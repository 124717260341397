div.stickyNotification {
    position: fixed;
    background-color: transparent;
    top: auto;
    left: 0;
    z-index: 150;
    width: 100vw;
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;

    @include media-breakpoint-up(md) {
        overflow: visible;
        margin: 0 auto;
        padding-left: 15px;
        padding-right: 15px;
    }
    
    @include media-breakpoint-up(xl) {
        width: 100%;
        padding: 0 calc(50vw - 790px);
    }

    &.standard-mobile-minimized {
        margin-top: -65px;
    }

    &.standard-mobile {
        margin-top: -20px;
    }

    &.checkout-mobile-minimized {
        margin-top: 65px;
    }

    &.checkout-mobile {
        margin-top: 110px;
    }

    &.checkout-desktop-minimized {
        margin-top: 80px;
    }

    &.checkout-desktop {
        margin-top: 125px;
    }
}