.stickyToolbar {
    position: fixed;
    bottom: 0;
    right: -75px;
    top: 190px;
    margin: auto 0;
    width: 90px;
    height: 395px;
    z-index: $zindex-sticky-toolbar;
    display: none;
    transition: right 0.2s linear;

    &:hover,
    &:focus {
        right: -10px;
        width: 120px;
        padding-left: 30px;

        i {
            opacity: 1;
        }

        & .stickyToolbar__list {
            left: 30px;
        }

        & > div {
            left: auto;
        }

        & .stickyToolbar__list__menuItem span {
            display: block;
            width: 100%;
            font-size: 12px;
            transform: rotate3d(0, 0, 1, 0deg) translate(0, 0);
            height: 10px;
            line-height: 1;
        }
    }

    @media only screen and (orientation: landscape) and (min-height: 768px) {
        display: block;
    }
    
    i {
        font-size: 42px;
        opacity: 0;

        &::before {
            color: $white-2;
        }
    }

    & > div {
        position: absolute;
        bottom: 0;
        left: 20px;
    }

    &__list {
        position: absolute;
        bottom: 55px;
        left: 0;
        transition: 
            left 0.2s linear,
            height 0.2 linear;

        &__menuItem {
            display: block;
            height: 60px;
            width: 60px;
            border-radius: 3px;
            background-color: $blue;
            margin-bottom: 5px;
            color: $white-2;
            text-align: center;
            box-shadow: 0 0 5px $white;

            &:hover {
                cursor: pointer;
            }

            &--whiteBack {
                background-color: $white-2;
                border: 1px solid $gray-2;
    
                i::before {
                    color: $blue;
                    font-size: 40px;
                }

                &:not(.collapsed) {
                    i::before {
                        content: "\e920";
                    }
                }
            }

            input {
                position: absolute;
                opacity: 0;

                &:hover {
                    cursor: pointer;
                }

                &:checked ~ {
                    & .header__popUp {
                        opacity: 1;
                    }

                    & .stickyToolbar__cart {
                        left: -350px;
                    }

                    & .stickyToolbar__myList {
                        left: -350px;
                        max-height: 385px;

                        .header__shoppingList__listWrapper {
                            height: 210px;
                        }
                    }

                    & .stickyToolbar__contact {
                        left: -440px;
                        height: fit-content;
                    }

                    & .stickyToolbar__faq {
                        left: -350px;
                        max-height: 385px;
                    }

                    & .stickyToolbar__tools {
                        left: -350px;
                    }
                }
            }

            span {
                display: block;
                width: 100%;
                font-size: 12px;
                line-height: 0;
                transform: rotate3d(0, 0, 1, -90deg) translate(18px, -21px);
                transition: transform 0.2s linear, rotate3d 0.2s linear;
            }
    
            label {
                height: 100%;
                width: 100%;

                &:hover {
                    cursor: pointer;
                }
            }

            &:first-of-type {
                .header__popUp {
                    &::before,
                    &::after {
                        top: 15px;
                    }
                }
            }

            &:nth-of-type(3) {
                .header__popUp {
                    &::before,
                    &::after {
                        top: 145px;
                    }
                }
            }
        }

        &__popUp {
            text-align: left;
            padding: 20px;
            display: block;
            min-height: auto;
            width: 330px;
            background-color: $white-2;
            border: 1px solid $gray-2;
            left: 100px;
            top: 0;
            opacity: 0;
            z-index: -1;
            transition: left .2s, opacity .2s;

            &::before {
                border-color: transparent transparent transparent $white-2;
                z-index: 100;
                left: 327px;
            }

            &::after {
                border-color: transparent transparent transparent $gray-2;
                left: 329px;
            }

            &.stickyToolbar__myList {
                text-align: center;
            }
        }
    }

    &__myList {        
        &::before,
        &::after {
            top: 80px;
        }
    }

    &__faq {
        max-height: 300px;
        
        &__singleQABox {
            p {
                &:last-of-type {
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
            }
        }

        &__scrollWrapper {
            height: 170px;
            overflow: auto;
        }
    }

    &__contact {
        width: 420px;
        height: 155px;
        top: 165px;
        bottom: -20px;

        &::before,
        &::after {
            top: 45px;
        }

        &::before {
            left: 417px;
        }

        &::after {
            left: 419px;
        }

        .h5 {
            line-height: 20px;
        }

        i {
            position: absolute;
            left: 0;
            top: -4px;

            &::before {
                color: $blue;
                font-size: 24px;
                line-height: 24px;
            }
        }

        &__iconText {
            text-align: left;
            position: relative;
            left: 34px;
        }
    }

    &__tools {
        top: 65px;
        bottom: -60px;
        max-height: 255px;

        &::before,
        &::after {
            top: 210px;
        }
    }
    &_myCart-scroll{
        height: 160px;
        overflow: hidden;
        overflow-y: auto;
    }

    .stickyToolbar__list__menuItem:nth-child(1) {
        
        .stickyToolbar__myList, 
        .stickyToolbar__contact {
            top: 0px;

            &::before, 
            &::after {
                top: 15px;
            }
        }

        .stickyToolbar__faq {
            top: -65px;

            &::before, 
            &::after {
                top: 80px;
            }
        }

        .stickyToolbar__tools { 
            top: -80px;
            height: 205px;

            .configurator-Overflow {
                overflow-x: hidden;
                height: 165px;
            }

            &::before, 
            &::after {
                top: 95px;
            }
        }        
    }

    .stickyToolbar__list__menuItem:nth-child(2) {
        
        .stickyToolbar__contact,
        .stickyToolbar__tools {
            top: 0px;

            &::before, &::after {
                top: 80px;
            }
        }

        .stickyToolbar__faq {
            top: -65px;

            &::before, &::after {
                top: 145px;
            }
        }
    }

    .stickyToolbar__list__menuItem:nth-child(3) {
        
        .stickyToolbar__tools {
            top: 0px;

            &::before, &::after {
                top: 145px;
            }
        }     

        .stickyToolbar__contact {
            top: 65px;

            &::before, &::after {
                top: 80px;
            }
        }        
    }

    .stickyToolbar__list__menuItem:nth-child(4) {
       
        .stickyToolbar__tools {
            top: 0px;

            &::before, &::after {
                top: 210px;
            }
        }
    }
}

#stickyBarAnchor {
    z-index: $zindex-ccc-btn;
}

.ccc_Anchor{
    bottom: 50px !important;

    @media only screen and (orientation: landscape) and (min-height: 767px) {
        bottom: 0px !important;
    }   
}
