.slider-textbox {
  min-height: 360px;
  background-size: cover;
  padding: 30px;
  background: $white-1;
  opacity: 0.95;
}

.slider-position {
  max-height: 500px;
  width: 100%;
  
  img {
    width: 100%;
    height: 550px;
    object-fit: cover;
  }
}

.carousel {
  min-height: 400px;
  width: 100%;

  .slider-content {
    margin-bottom: 40px;
  }
}

.carousel-control {
  text-shadow: none;
  opacity: 1;
  width: 90px;

  & > i {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 35px;
    margin: auto 0;
    width: 40px;
    height: 40px;
    font-size: 40px;
    color: $blue;
    transition: color 0.2s;
  }

  &.right > i {
    left: auto;
    right: 35px;
  }

  &:hover {
    opacity: 1;

    & > i {
      color: $cyan;
    }
  }
}

.carousel-indicators {
  width: auto;
  margin-bottom: 25px;

  li {
    background-color: $white-2;
    margin: 5px;
    width: 8px;
    height: 8px;
    border: 1px solid $gray-2;
    opacity: 1;
    transition: background-color 0.5s, border-color 0.5s;

    &.active {
      border-color: $cyan;
      background-color: $cyan;
      margin: 5px;
      width: 8px;
      height: 8px;
    }
  }
}

.slider-button-text {
  position: absolute;
  bottom: 10px;
  left: 30px;
}

.slider-content {
  overflow: hidden;
  margin-bottom: 20px;
  font-family: $body-font-family-regular;
  font-size: 16px;
  color: $blue;
  line-height: 24px;
}

.slider-secondheader {
  font-family: $headline-font-family-bold;
  font-size: 40px;
  color: $blue;
  text-decoration: none solid $blue;
  line-height: 48px;
  margin-bottom: 10px;
}

.slider-underline {
  width: 144px;
  height: 8px;
  border-radius: 6px;
  background-image: linear-gradient(
    -45deg,
    $yellow 0%,
    $green 37%,
    $cyan 73%,
    $blue 100%
  );
  background-position: center center;
  background-size: cover;
  margin-bottom: 20px;
}

.slider-firstheader {
  margin: 0;
  font-family: $body-font-family-serif;
  font-size: 40px;
  color: $blue;
  text-decoration: none solid $blue;
  line-height: 48px;
}



@include media-breakpoint-up(md) {
  .slider-textbox-position {
    left: 90px;
  }

  .slider-textbox-position {
    position: absolute;
    bottom: 10%;
  }

  .slider-position {
    position: relative;
    width: 100%;
  }

  .slider-textbox {
    background: $white-1;
    max-width: 517px;
  }
}

@include media-breakpoint-up(xl) {
  .slider-textbox-position {
    left: calc(50vw - 790px);
  }
}

@include media-breakpoint-down(sm) {
  .slider-textbox-position {
    width: 100%;
  }

  .slider-position {
    width: 100%;
    min-height: 500px;

    img {
      max-height: 300px;
    }
  }

  .carousel-control {
    height: 300px;
  }

  .carousel-indicators {
    top: 270px;
}

  .slider-textbox {
    background: $white-1;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }

  .slider-button-text {
    left: 15px;
  }

  .glyphicon.glyphicon-chevron-left {
    top: 25%;
    color: $blue;
  }

  .glyphicon.glyphicon-chevron-right {
    top: 25%;
    color: $blue;
  }
}
