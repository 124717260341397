.h-plus-first-template { 
    padding: 0;

    .first-template-box-image {
        width: 100%;
        max-height: 460x;
    }

    .first-template-box-content {
        margin-top: 20px;
        padding: 0;
    }

    .first-template-text-box {
        margin-top: 30px;
    }
    
    @include media-breakpoint-down(sm) {
        padding: 10px;
        
        .first-template-box-image {
            max-height: 300x;
            margin-top: 30px
        }
        
        .first-template-box-content {
            margin-top: 5px;
        }

        .first-template-section-box,
        .first-template-text-box  {
            margin-top: 15px;
        }    
    }
}