.h-plus-second-template {
    .second-template-banner,
    .second-template-block,
    .second-template-text-box {
        margin-bottom: 60px;
    }

    .second-template-image { 
        padding: 0;
        clear: both;
        
        img {
            width: 100%;
            max-height: 300px;
        }
    }
    
    .second-template-odd-content {
        padding: 0 0 0 20px;
    }

    .second-template-even-content {
        padding: 0 20px 0 0;
    }

    @include media-breakpoint-down(sm) {
        padding: 10px;

        .second-template-banner,
        .second-template-text-box {
            margin-bottom: 40px;
        }

        .second-template-block {
            margin-bottom: 50px;   
        }

        .second-template-even-content,
        .second-template-odd-content {
            padding: 0;
        }

        .second-template-odd-content > .h-plus-secondheader,
        .second-template-even-content > .h-plus-secondheader {            
            @include clearfix();
        } 
    }
}