.pagination {
    margin: 0;
}

.page-item:last-child .page-link,
.page-item:first-child .page-link {
    line-height: 12px;
}

.pagination > li > a, 
.pagination > li > span {
    color: $blue;

    i {
        height: 20px;
        width: 15px;
        font-size: 24px;
    }
}