.productList {
    &__pagination {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        li {
            display: inline;
            margin-left: 5px;
            margin-right: 5px;
        }

        .form-group-toggle .btn {
            padding: 0 20px;
        }
    }

    &Header {
        &__controls {
            background-color: $white-1;
            border: none;
        }

        &__sortDropdown {
            .select-form-control-wrapper {
                width: 210px;
            }
        }
    }
    
    &Footer {
        background-color: $white-1;
        text-align: center;
        min-height: 100px;
        width: 100%;

        @include media-breakpoint-up(lg) {
            min-height: 60px;
        }
        
        &__pagination {
            color: $blue;

            li {
                display: inline;
                margin-left: 5px;
                margin-right: 5px;
            }

            &__viewButton {
                i {
                    font-size: 25px;
                }

                &:hover {
                    i:before {
                        color: inherit;
                    }
                }
            }
        }
        
        &__sizeControl {
            width: 80%;

            @include media-breakpoint-up(lg) {
                width: 100%;
            }
            
            .select-form-control-wrapper {
                width: 50%;
            }
        }
    }
}