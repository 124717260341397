.payment-paymetric {
    color: $blue;

    tbody td {
        vertical-align: middle;
    }

    & > br {
        display: none;
    }

    // adds missing colon because we cannot adjust markup 
    td:first-of-type.DataInterceptCreditCardType::after,
    td:first-of-type.DataInterceptCreditCardNumber::after,
    td:first-of-type.DataInterceptExpirationDate::after,
    td:first-of-type.DataInterceptCVV::after {
        content: ":";
    }

    select {
        @extend .select-form-control-wrapper;
        margin: 5px 0;
        width: 100%;
        cursor: pointer;
    }

    input {
        @extend .payment-input;
        margin: 5px 0;

        &:hover {
            border-color: $blue;
        }
    }

    .DataInterceptExpirationDate input {
        width: 50px !important;
    }

    #PayNowButton {
        display: none;
    }

    .DataInterceptErrors {
        @extend .alert;
        @extend .alert-danger;
        // that the unfilled container is not displayed before
        border-width: 0;
        padding: 0 10px;
        margin: 0;
    }
}