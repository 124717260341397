.navbar-inverse {
  margin-top: auto; // nessesary that footer always on bottom of the page --> flex
  background-color: $white-2;
}

.newsletterBox {
  border: 1px solid $gray-2;
  border-radius: 3px;
  padding: 20px 10px;

  &__text {
    margin-bottom: 20px;
  }

  &__checkBox {
    font-size: 14px;
    margin-bottom: 20px;

    label {
      text-decoration: underline;
    }
  }

  &__submitBtn {
    width: 100px;
    margin-bottom: 20px;
  }

  &__img {
    max-width: 100%;
  }
}

.footer {
  margin-top: 30px;
}

.prefooter {
  background-color: $white-1;
  margin-top: 20px;

  &__wrapper {
    min-height: 320px;
    padding-top: 20px;
    padding-bottom: 40px;
  }

  &__contact-items {
    text-align: center;
  }

  .h1,
  .h2,
  .h3 {
    color: $blue;
    display: inline;
  }

  &__contactlink {
    font-family: $headline-font-family-bold;
    font-size: 16px;
    box-shadow: inset 0 -1px 0 0 $blue;
    transition: box-shadow 0.2s;
    color: $blue !important;

    &:hover {
      box-shadow: inset 0 -2px 0 0 $blue;
    }
  }
}

.subfooter {
  height: 40px;
  background-color: $blue;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white-2;
  font-size: 14px;

  // if you edit this media query, please take a look at ".mobileNavigation", here we're react of this sticky element
  @media only screen and (orientation: portrait),
         only screen and (max-height: 766px) {
    margin-bottom: $navigation-mobile-height;
  }
}

.footer-ds {
  background-color: $white-2;
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;

  @include media-breakpoint-up(md) {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  * {
    color: $gray-20;
  }

  &__positioning {
    position: relative;
  }

  &__address {
    color: $gray-20;
  }

  &__links {
    font-family: $body-font-family-regular;
    color: $gray-20 !important;
    transition: all 0.5s;

    &:hover {
      color: $cyan !important;
    }
  }

  &__link-column {
    margin-bottom: 40px;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
  }

  &__ssu-column {
    .h5 {
      margin-bottom: 20px;

      @include media-breakpoint-up(md) {
        margin-bottom: 10px;
      }
    }
    .additional-legal-content {
      font-size: 12px;
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 15px;
      width: 100%;
      @include media-breakpoint-up(md) {
        padding-top: 5px;
        width: 70%;
      }
    }

    div {
      margin-bottom: 40px;

      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
      }
    }
  }

  &__social-media-container {
    position: absolute;
    right: 20px;
    top: 0;

    @include media-breakpoint-up(md) {
      top: initial;
      bottom: 0;
    }
    
    @include media-breakpoint-up(xl) {
      right: 0;
    }

    i {
      width: 30px;
      height: 30px;
      position: relative;
      border-radius: 50%;

      &::before {
        position: absolute;
        z-index: 1;
        color: $white-2;
        font-size: 24px;
        top: 3px;
        left: 3px;
      }

      &::after {
        content: "";
        display: block;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: $gray-20;
        border-radius: 50%;
        transition: transform 0.2s, background 0.2s;
      }

      &:hover {
        &::after {
          transform: scale(1.2);
        }
      }
    }

    .icon-social_media {
      &_twitter {
        &:hover::after {
          background: #1da1f2;
        }
      }

      &_linkedin {
        &:hover::after {
          background: #0077b5;
        }
      }

      &_facebook {
        &:hover::after {
          background: #4267b2;
        }
      }

      &_xing {
        &:hover::after {
          background: #106f71;
        }
      }

      &_youtube {
        &:hover::after {
          background: #ff0000;
        }
      }

      &_instagram {
        &:hover::after {
          background: radial-gradient(
            circle at 30% 107%,
            #fdf497 0%,
            #fdf497 5%,
            #fd5949 45%,
            #d6249f 60%,
            #285aeb 90%
          );
        }
      }
    }
  }

  &__homelink {
    box-shadow: inset 0 -1px 0 0 $gray-20;
    transition: box-shadow 0.2s;
    font-family: $body-font-family-regular;
    color: $gray-20;
    font-size: 16px;

    &:hover {
      box-shadow: inset 0 -2px 0 0 $gray-20;
      color: $gray-20 !important;
      text-decoration: none !important;
    }

    @include media-breakpoint-up(lg) {
      font-family: $headline-font-family-bold;
    }
  }
}
