.checkoutOrderReview {
    &__summary {
        &__singleBox {
            background-color: $white-1;
            min-height: 210px;

            @include media-breakpoint-up(sm) {
                min-height: 185px;
            }

            .h3 {
                margin-bottom: 10px;

                @include media-breakpoint-up(sm) {
                    margin-bottom: 20px;
                }
            }
        }
    }
}