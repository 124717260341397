#renderAnchorShowOverview {
    height: 300px;
    overflow-y: scroll;
    overflow-x: hidden;
}
#renderAnchorShowOverview {
    .modal-content {
        padding: 30px 10px;
    }

    .orderHistory__tableBody {
        padding-right: 20px;
    }
}
#renderAnchorShowOverview::-webkit-scrollbar {
    width: 5px;
    padding-right: -20px
  }
  
#renderAnchorShowOverview::-webkit-scrollbar-track {
    background-color: $gray-2;
}

#renderAnchorShowOverview::-webkit-scrollbar-thumb {
    border-radius: 100px;
    border: 6px solid rgba(0, 0, 0, 0.18);
    border-left: 0;
    border-right: 0;
    background-color: $blue;
}

.showOverviewModal {
    top:485px;
}