.categoryHeaderContainer-withImage {
    color: $blue;
    width: 100%;

    @include media-breakpoint-up(md) {
        height: 240px;
    }
}

.categoryHeaderBannerImage {
    width: 100%;
    height: auto;
    min-height: 120px;
    object-fit: cover;
    
    @include media-breakpoint-up(md) {
        height: 240px;
        width: 100%;
    }
}

.categoryHeaderBannerContent-withImage {
    margin-bottom: 30px;
    padding: 20px 10px;
    background-color: $white-1;
    
    @include media-breakpoint-up(md) {
        position: absolute;
        top: 40px;
        left: 30px;
        right: 50%;
        height: 160px;
        background-color: $white-1;
        width: 500px;
        cursor: pointer;
        -webkit-transition: width 0.4s ease;  
        -moz-transition: width 0.4s ease;  
        -o-transition: width 0.4s ease;  
        -ms-transition: width 0.4s ease;  
        transition: width 0.4s ease;
        padding: 20px;
    }
}

.categoryHeaderBannerContent-withImage,
.categoryHeaderBannerContent-withoutImage {
    &:not(.transform-active) .categorieHeaderBannerText > p {
        @extend .line-clamp-3;
        overflow: hidden;
    }
}

.categoryHeaderBannerContent-withoutImage {
    &:not(.transform-active) .categorieHeaderBannerText > p {
        @include media-breakpoint-up(md) {
            -webkit-line-clamp: 7;
        }
    }
}

.categorieHeaderBannerHeadLine {
    font-size: $headline-h3-font-size;
    line-height: $headline-h3-line-height;

    @include media-breakpoint-up(md) {
        font-size: $headline-h1-font-size;
        line-height: $categoryHeaderBannerLineHeight;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        //reduce margin, to match changed lineheight
        margin-top: -10px;
        margin-bottom: 0px!important;
    }
}

.categorieHeaderBannerText {
    @include media-breakpoint-up(md) {
        height: 75px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.transform-active:not(.categoryHeaderBannerContent-withoutImage) {@include media-breakpoint-up(md) {width: calc(100% - 60px);}}

.categoryHeaderContainer-withoutImage {
    color: $blue;
}

.categoryHeaderBannerContent-withoutImage {
    margin-bottom: 30px;
    background-color: $white;

    .categorieHeaderBannerText {
        height: auto;
        overflow: visible;
        column-width: 600px;
        column-gap: 40px;
    }
}
