.overviewBox {
    &__wrapper {
        background-color: $white-1;
        margin-top: 20px;
        padding-left: 10px;
        padding-right: 10px;
        position: sticky;
        position: -webkit-sticky;
        top: 100px;

        @include media-breakpoint-up(md) {
            margin-top: 30px;
        }

        @include media-breakpoint-up(lg) {
            margin-top: 0;
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    &__content {
        & > .h3 {
            margin-bottom: 20px;
        }

        .additional-information {
            min-height: 100px;
        }

        @include media-breakpoint-up(xl) {
            .cc_rfq_link_button {
                width: 100%;
            }
        }
    }

    &__line {
        p:first-of-type {
            width: auto;
            max-width: 100%;
            text-overflow: ellipsis;
            overflow: visible;
            float: left;
        }

        p:last-of-type {
            text-align: right;
            word-break: break-all;
            word-break: break-word;
        }
    }

    &__promotionBox {
        div:last-of-type {
            border-bottom: 1px solid $gray-2;
        }
    }

    &__shippingCostBox {
        border-bottom: 1px solid $gray-2;

        &__toggleIcon {
            transform: rotate(0deg);
            transition: transform 0.2s;

            &:not(.collapsed) {
                transform: rotate(180deg);
                
                & + .collapseHide {
                    opacity: 0;
                    position: relative;
                    z-index: -1;
                }
            }
        }

        &__collapseHeader {
            opacity: 1;
            transition: opacity 0.2s;
        }

        i {
            float: left;
        }
    }

    &__totalsBox {
        border-bottom: 1px solid $gray-2;
    }

    &__buttonBox {
        button {
            width: 100%;
        }
    }
}