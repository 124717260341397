.h-plus-first-template,
.h-plus-second-template,
.h-plus-third-template {
    color: $blue;
}

@include media-breakpoint-up(md) {
    .h-plus-banner-container {
        position: absolute;
        top: 30px;
        left: 30px;
        width: 635px;
        padding: 30px;
        max-height: 304px;
        background-color: $white-1;

    }
    
    .h-plus-banner-section {
        position: relative; 
        margin: 30px 0;
        width: 100%;
    }

    .h-plus-banner-image {
        width: 100%;
        height: 364px;
    }
}

@include media-breakpoint-down(sm) {
    .h-plus-banner-container { 
        padding: 0; 
    }
}

.h-plus-banner-image {
    min-height: 130px;
    width: 100%;
    object-fit: cover;
    object-position: right bottom;
}

.h-plus-firstheader,
.h-plus-secondheader {
    font-family: $body-font-family-serif;
    font-size: $headline-h3-font-size;
    line-height: $headline-h3-line-height;
    color: $blue;
}

.h-plus-secondheader {
    font-family: $headline-font-family-bold;
}