.checkout {
    &__box {
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 10px;
        padding-right: 10px;

        @include media-breakpoint-up(md) {
            padding-right: 20px;
            padding-left: 20px;
        }
    }

    &__header {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    &__subHeadline {
        font-size: 24px;
        line-height: 29px;
    }

    &__orderInfoBox {
        color: $white-2;
        background-color: $cyan;

        .form-group-toggle {
            width: 180px;
            margin-top: 15px;

            @include media-breakpoint-up(lg) {
                margin-top: 20px;
            }
        }
    }

    &__orderAdressBox {
        background-color: $white-1;
        
        @include media-breakpoint-up(md) {
            height: 100%;
            margin-top: 0;
        }

        &__email {
            line-break: anywhere;
            word-break: break-all;
        }
    }

    &__orderProductGroupBox {
        background-color: $white-1;
        position: relative;

        @include media-breakpoint-up(md) {
            margin-top: 10px;
        }

        &__references {
            margin-bottom: 20px;

            input {
                min-height: 40px;
            }

            textarea {
                resize: none;
            }
        }

        &__address {
            padding-top: 20px;
            padding-bottom: 20px;
            border: 1px solid transparent;
            border-top-color: $gray-2;
            border-bottom-color: $gray-2;
            height: 100%;

            @include media-breakpoint-up(lg) {
                border-top-color: transparent;
                border-bottom-color: transparent;
                border-left-color: $gray-2;
                border-right-color: $gray-2;
                padding-left: 30px;
                padding-right: 30px;
                padding-top: 0;
                padding-bottom: 0;
            }

            .headline {
                font-family: $headline-font-family-bold;
                font-size: $headline-h5-font-size;
                line-height: $headline-h5-line-height;
            }
            
            p:first-of-type {
                margin-bottom: 20px;
            }

            a {
                margin-top: 20px;
            }

            i {
                position: relative;
                top: 7px;
            }
        }
        
        &__method {
            padding-top: 20px;
            margin-bottom: 20px;

            @include media-breakpoint-up(lg) {
                padding-top: 0;
            }    

            .radio-form {
                @include media-breakpoint-up(md) {
                    column-count: 2;
                }
            }
        }

        &__reqDelDt {
            padding-top: 20px;
            margin-bottom: 20px;
      
            input {
                min-height: 40px;            
            }

            span {
                max-height: 40px;
            }         
        }

        &--individual {
            padding-top: 10px;
            padding-bottom: 10px;

            @include media-breakpoint-up(md) {
                margin-top: 20px;
                padding-top: 20px;
                padding-bottom: 20px;
            }
        }
    }

    &__orderItemsBox {
        background-color: $white-1;
        margin-top: 20px;
    }

    &ProductList {
        &__list {
            background-color: $white-1;
            padding-top: 20px;
            padding-bottom: 20px;
            position: relative;

            &--individual {
                border-top: 2px solid $white;
                padding-bottom: 0;

                @include media-breakpoint-up(lg) {
                    margin-top: 30px;
                    padding-top: 30px;
                }

                .checkoutProductList__list__footer {
                    padding-top: 15px;
                    padding-bottom: 5px;

                    @include media-breakpoint-up(lg) {
                        padding-top: 20px;
                    }
                }
            }
            
            &__subTotal {
                @include media-breakpoint-up(lg) {
                    font-size: 18px;
                    line-height: 22px;
                }
            }

            &__headerLine {
                margin-bottom: 20px;
                font-size: 18px;
                line-height: 22px;
            }

            &__wrapper {
                margin-top: 20px;
                overflow: hidden;
                transition: height 0.2s;

                @include media-breakpoint-up(md) {
                    margin-top: 30px;
                }
            }
            
            &__toggleIcon {
                position: absolute;
                right: 15px;
                top: 10px;
                cursor: pointer;
                z-index: 5;
                transform: rotate(180deg);
                transition: transform 0.2s;

                &.collapsed {
                    transform: rotate(0);
                }

                @include media-breakpoint-up(lg) {
                    top: 20px;
                }

                &:not(.collapsed) ~ .checkoutProductList__list__collapseHeader {
                    .collapseHide {
                        display: none;
                    }
                }
            }

            &__miniSummary {
                line-height: 20px;
                
                @include media-breakpoint-up(lg) {
                    margin-top: 10px;
                }

                @include media-breakpoint-up(xl) {
                    .row {
                        overflow: hidden;
                    }
                }
            }

            &__miniSummaryBox {
                padding: 10px;
                min-height: 80px;
                background-color: $white-3;

                p:last-of-type {
                    line-break: anywhere;
                }

                @include media-breakpoint-up(xl) {
                    height: 100%;
                }
            }

            &__headline {
                border-bottom: 1px solid $gray-2;
                font-size: 18px;
                line-height: 22px;

                @include media-breakpoint-up(lg) {
                    font-size: 24px;
                    line-height: 29px;
                }
            }
            
            &__header {
                background-color: $gray-2;
            }

            &__subheader {
                margin-bottom: 20px;
            }
        
            &__item {
                border-bottom: 1px solid $gray-2;

                @include media-breakpoint-up(md) {
                    min-height: 100px;
                }
        
                &:first-of-type {
                    border-top: 1px solid $gray-2;
        
                    @include media-breakpoint-up(md) {
                        border-top: none;
                    }
                }

                &--first {                    
                    border-top: 1px solid $gray-2;
                }
            }

            &__scheduledDeliveryItem {
                padding-top: 10px;

                &:first-child {
                    padding-top: 0px;
                }
            }   

            &__footer {
                &:not(:last-of-type) {
                    border-bottom: 2px solid $white-2;
                    margin-bottom: 30px;
                    padding-bottom: 30px;
                }

                @include media-breakpoint-up(md) {
                    padding-right: 85px;
                }
            }

            &__img {
                img {
                    max-height: 80px;
                    max-width: 80px;
                    width: 100%;
                    height: 100%;
                }
            }
        }

        &__subList {
            padding-top: 20px;
            padding-bottom: 20px;
            
            &:not(:first-of-type) {
                border-top: 2px solid $white-2;
            }

            &:not(:last-of-type) {
                padding-bottom: 20px;
            }

            @include media-breakpoint-up(lg) {
                padding-top: 30px;

                &:first-of-type {
                    padding-top: 15px;
                }

                &:not(:last-of-type) {                    
                    padding-bottom: 30px;
                }
            }
        }
    }
}