.myAccountDashboard {
    &__sectionRow {
        & > div {
            @include media-breakpoint-between(sm, md) {
                &:not(.no-dash):not(:last-of-type):nth-child(odd) {
                        position: relative;

                    &::before {
                        content: "";
                        position: absolute;
                        height: 170px;
                        width: 1px;
                        background-color: $gray-2;
                        right: 0;
                        top: 50px;
                    }
                }
            }
            
            @include media-breakpoint-up(lg) {
                &:not(.no-dash):not(:last-of-type):not(:nth-child(4n)) {
                        position: relative;

                    &::before {
                        content: "";
                        position: absolute;
                        height: 170px;
                        width: 1px;
                        background-color: $gray-2;
                        right: 0;
                        top: 60px;
                    }
                }
            }
        }
    }

    &__tile {
        height: 230px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $white-1;
        margin-top: 20px;

        @include media-breakpoint-up(lg) {
            margin-top: 30px;
        }

        &--userInfo {
            .myAccountDashboard__tileContent {
                max-width: initial;

                &__name {
                    font-size: 18px;
                    font-weight: bold;
                }
    
                p {
                    font-size: 16px;
                    line-height: 24px;
                    font-family: $body-font-family-regular;
                    line-break: anywhere;
                    word-break: break-all;
                    white-space: break-spaces;
                }
            }
        }

        &--rewardPoints {
            background-color: $cyan;
        }

        &--rewardPoints--progress {
            background-color: $cyan;
            margin-top: 0px;
            .bookmarkRibbonOut {
                position: absolute;
                top: 0px;
                left: 39px;
                border-bottom: 15px solid transparent;
                z-index: 100;
                width: 0; 
                height: 78px;
                border-right: 28px solid #fff;
                border-left: 28px solid #fff;
            }
            .bookmarkRibbon {
                position: absolute;
                top: 0px;
                left: 41px;
                width: 0; 
                height: 75px;
                border-bottom: 15px solid transparent;
                z-index: 100;
            }
            .progress-tile {
                min-width: 100%;
                .container {
                    height: 30px;
                    padding-top: 5px;
                    .progress {
                        height: 15px;
                        background: #fff;
                        border-radius: 15px;
                        .progress-bar {
                            border: 2px solid;
                            height: 15px;
                            border-radius: 10px;
                        }
                    }
                }
            }
        }

        p {
            font-size: 24px;
            line-height: 29px;
            font-family: $headline-font-family-bold;
            text-align: center;
        }

        i {
            width: 100%;
            text-align: center;

            &::before {
                font-size: 90px;
                color: $cyan;
            }
        }

        button {
            display: block;
        }
    }

    &__tileContent {
        width: 90%;
        max-width: 190px;
    }
}