.bgcimg {
  border-radius: 0;
  overflow: hidden;
  min-height: 260px;
  background-size: cover;
  height: 32vw;
  max-height: 768px;
}

.two-tile-content-container{
  padding: 0 15px;

  @include media-breakpoint-up(xl) {
    padding: 0;
  }
}

.two-teaser-tile-header {
  color: $blue;
}

.two-teaser-tile-container {
  max-width: 1800px;
  padding: 20px 0;
  background-size: cover;
}

.two-teaser-tile-header h2 {
  margin-bottom: 25px;
  margin-top: 35px;
  color: $blue;
  font-family: $headline-font-family-bold;
  font-size: $headline-h1-font-size;
  text-decoration: none;
}

.two-teaser-tile-subtitle {
  color: $blue;
  font-size: 20px;
  font-family: $headline-font-family-bold;
  text-decoration: none;
}

.two-teaser-tile-content {
  max-height: 100%;
  z-index: 1;
  color: $blue;
  background: transparentize($white-1, 0.1);
}

.two-teaser-tile-content-text {
  margin: 0;
  min-height: 198px;
  max-height: 0;
  font-size: 16px;
  opacity: 0;
  overflow-y: auto;
  transition: max-height 0.5s, opacity 0.5s linear;
}

.two-teaser-tile-link:hover {
  padding-bottom: 2px;
  color: $blue;
  text-decoration: none;
  box-shadow: inset 0 -3px 0 0 $blue;
  transition: box-shadow 0.3s ease-in-out;
}

.two-teaser-tile-link {
  color: $blue;
  font-family: $headline-font-family-bold;
  font-size: $body-font-size;
  text-decoration: none;
}

.two-teaser-tile-link-icon {
  width: 20px;
  height: 20px;
  background: $blue;
  font-size: 16px;
  color: $white-1;
  border-radius: 50%;
}

.two-teaser-tile-content {
  height: 98px;
  background-color: $white-1;
}

.card.two-teaser-tile {
  min-height: 260px;
  height: 32vw;
  max-height: 550px;

  @include media-breakpoint-down(sm) {
    height: 72vw;
  }
}

.two-teaser-tile-background-image {
  background-size: cover;
}

@include media-breakpoint-down(xs) {
  .two-teaser-tile-content {
    min-height: 98px;
    opacity: 1;
    padding: 10px;
  }

  .two-teaser-tile-header h2 {
    margin-bottom: 15px;
    margin-top: 0;
    font-family: $headline-font-family-bold;
    font-size: $headline-h3-font-size;
    color: $blue;
    text-decoration: none;
  }

  .two-teaser-tile-link {
    margin-left: 5px;
  }
}
