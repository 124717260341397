
.pdp-tabs {
    @include media-breakpoint-up(md) {
        .tab-container {
            background-color: $white-1;
        }
        
        .panel-heading {
            display: none;
        }
        
        .panel-body {
            padding: 15px 0;
        }
        
        .panel {
            border: none;
            box-shadow: none;
            background-color: transparent;
        }
        
        .panel-collapse {
            height: auto !important;
            
            &.collapse {
                display: block;
            }
        }
        
        .nav-tabs {
            display: inline-block;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
            border: 0;
            
            li {
                position: static;
                width: 200px;
                
                &:last-child:after {
                    content: '';
                    position: absolute;
                    display: block;
                    right: 0;
                    bottom: -10px;
                    left: 0;
                    width: 200px;
                    height: 2px;
                    background-color: $blue;
                    margin-top: 0;
                    transition: margin 0.4s ease-in-out;
                }
                
                & > a,
                &.active > a {
                    margin: 0;
                    padding-bottom: 0;
                    font-family: $headline-font-family-bold;
                    background-color: transparent;
                    font-size: $headline-h4-font-size;
                    line-height: $headline-h4-line-height;
                    text-align: center;
                    color: $blue;
                    border: 0;
                    
                    &:hover,
                    &:focus {
                        background-color: transparent;
                        border: 0;
                    }
                }
                
                &.active:first-child ~ li:last-child:after { 
                    margin-left: 0;
                }
                
                
                &.active:nth-child(2) ~ li:last-child:after { 
                    margin-left: 200px;
                }
                
                &.active:nth-child(3) ~ li:last-child:after { 
                    margin-left: 400px;
                }
                
                &.active:nth-child(4) ~ li:last-child:after { 
                    margin-left: 600px;
                }
                
                &:last-child:after { 
                    margin-left: calc(100% - 200px);
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .tab-content {
            .tab-pane {
                display: block;
            }
        }

        .filterContainer {
            margin-bottom: 15px;
        }
        
        .nav-tabs {
            display: none;
        }
        
        .panel-title a {
            display: block;
        }
        
        .panel {
            margin: 0;
            box-shadow: none;
            border-radius: 0;
            margin-top: -2px;
        }
        
        .tab-pane {
            &:first-child {
                .panel {
                    border-radius: 5px 5px 0 0;
                }
            }
            
            &:last-child {
                .panel {
                    border-radius: 0 0 5px 5px;
                }
            }
        }
    }

    .panel-body {
        &__table {
            & > .row {
                min-height: 60px;
                align-items: center;
                position: relative;

                &::before {
                    content: "";
                    height: 1px;
                    width: calc(100% - 20px);
                    background-color: $gray-2;
                    bottom: 0;
                    position: absolute;
                    left: 10px;
                }
            }
        }
    }

    .panel-subHeading {
        margin-top: 20px;
        line-height: 40px;
        background-color: $gray-2;
    }
}
