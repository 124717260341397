.locale-switcher-page {

  &__continent-wrapper {
    height: auto;
    width: 100%;
    padding: 1rem;
    @include media-breakpoint-up(md) {
      padding: 2rem;
    }
    .head-line {
      color: $blue;
    }
    .sub-head-line {
      font-size: large;
      margin-bottom: 2rem;
    }
    &-background-grey {
      background-color: $white-1;
    }

  }

  &__country-item-wrapper {
    .country-item-flag {
      width: 25%;
    }
    .country-flag-wrapper {
      border: #a0a0a0;
      border-width: 1px;
      border-style: solid;
    }
    .-rectangular-flag {
      width: 15%;
    }
    .country-item-label {
      width: 75%;
      font-size: x-small;
      display: flex;
      flex-direction: row;
      justify-content: center;
      @include media-breakpoint-up(md) {
        font-size: medium;
      }
    }
  }
}