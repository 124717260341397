// div.coveo-accessible-button {
//     display: none;
// }

.coveo-hdm {
    /*******************************************************
    ***** Variables difinition *****************************
    *******************************************************/
    $coveo_border_color_default:    $gray-2;
    $coveo_border_color_active:     $blue;

    $coveo_icon_color_default:  $gray-20;
    $coveo_icon_color_active:   $cyan;

    $coveo_font_bold:       $headline-font-family-bold;
    $coveo_font_regular:    $headline-font-family-regular;
    $coveo_font_icon:       "hdm_font_icon";

    $coveo_input_color:     $gray-80;


    /*******************************************************
    ***** Coveo basics *************************************
    *******************************************************/
    &.CoveoResultLink.text-decoration-none {
        font-family: $body-font-family-regular;
        color: $blue;
        transition: color .2s;
        cursor: pointer;

        &:hover,
        &:hover > i {
            color: $cyan;
        }
        &:visited {
            color: $blue;
            &:hover {
                color: $cyan;
            }
        }

        &.no-hover {
            &:hover {
                color: inherit;
                text-decoration: none;
            }
            &:visited {
                color: $blue;
            }
        }
    }

    & .coveo-dynamic-hierarchical-facet-all {
        display: none;
    }

    & .coveo-dynamic-facet-value .coveo-checkbox-span-label-suffix {
        display: none;
    }

    & .coveo-dynamic-hierarchical-facet-value-count {
        display: none;
    }

    & .coveo-dynamic-hierarchical-facet-value.coveo-with-space > a {
        display: inline-flex;
    }

    &.CoveoResultAction.btn {
        color: $white-2;
        &:hover {
            transition: color .2s;
            text-decoration: none;
        }
    }
    &.CoveoResultLink.btn-secondary {
        color: $blue;
    }

    &.CoveoResultLink.btn-secondary:hover {
        color: $cyan;
        transition: color .2s;
        text-decoration: none;
        border-color: $cyan;
    }

    &.CoveoResultLink.btn-lg.gp_prod.btn-primary {
        height: 40px;
        font-size: 16px;
        line-height: 28px;
        &:hover > i {
            color: $white-2;
        }
    }

    &.CoveoResultLink.btn-secondary:visited {
        color: $blue;
        &:hover {
            transition: color .2s;
            text-decoration: none;
            color: $cyan;
        }
    }

    &.CoveoResultLink.btn-primary {
        color: $white-2;
    }

    &.CoveoResultLink.btn-primary:visited {
        color: $white-2;
        &:hover {
            color: $white-2;
        }
    }

    &.CoveoResultLink.btn-primary:hover {
        color: $white-2;
        transition: background-color .2s;
        text-decoration: none;
        &:visited {
            color: $white-2;
        }
    }

    & .coveo-list-layout.CoveoResult {
        border-bottom: 0;
        padding: 0;
        font-family: $body-font-family-regular;
    }
    
    &.CoveoSearchInterface {
        font-family: $coveo_font_regular;
        font-size: $body-font-size;
        line-height: $body-line-height;
        
        textarea, 
        input[type=text] {
            color: $coveo_input_color;
        }
        
        &.coveo-small-facets .coveo-facet-dropdown-header {
            @extend .btn;
            @extend .btn-lg;
            margin: 15px;
    
            &.coveo-dropdown-header-active {
                z-index: 951;
            }
        }

        &.coveo-small-facets {
            .coveo-facet-column.coveo-facet-dropdown-content {
                z-index: 1001;
            }
        }

        .coveo-main-section {
            max-width: none;
        }

        .coveo-query-summary-no-results-string {
            display: none;
        }

        .coveo-query-summary-cancel-last {
            display: none;
        }

        .coveo-results-header {
            box-shadow: none;
        }

        .coveo-facet-column {
            width: 25%;
            padding-top: 10px;
        }

        .coveo-results-column {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    .coveo-dropdown-background {
        z-index: 950;
    }

    .CoveoSortDropdown {
        @extend .select-form-control-wrapper;
        background-color: $white-2;

        .coveo-dropdown {
            @extend .select-form-control;
            background: none;
            padding-top: 0;
            padding-bottom: 0;
            text-transform: none;
            font-weight: normal;

            &.coveo-selected,
            &.coveo-selected:hover {
                border-bottom: 0;
                outline: 0;
            }
        }
    }

    .coveo-result-layout-selector {
        position: relative;
        background-color: $white-2;
        display: flex;
        justify-content: center;
        align-items: center;
        width: auto;
        height: 40px;
        text-align: center;
        color: $blue;
        cursor: pointer;
        transition: color 0.3s linear;
        margin: 0;
        padding: 0 20px;
        border-bottom: 0;

        &.coveo-selected,
        &.coveo-selected:hover {
            border: 0;
            background-color: $white-2;
            color: $white-2;
        }

        .coveo-icon {
            font-size: 24px;
            height: 23px;
            font-weight: normal;
            width: 23px;
            margin: 0;
            z-index: 3;
        }

        .coveo-list-layout-icon::before {
            position: relative;
            content: "\e9d2";
            font-family: $coveo_font_icon;
            z-index: 2;
        }

        .coveo-card-layout-icon::before {
            position: relative;
            content: "\e9d1";
            font-family: $coveo_font_icon;
            z-index: 2;
        }

        .coveo-card-svg,
        .coveo-list-svg,
        .coveo-result-layout-selector-caption {
            display: none;
            z-index: 2;
        }

    }
    
    .CoveoResultLayoutSelector > :first-child  {
        transition: transform 0.3s linear, color 0.3s linear;

        &.coveo-selected {
            ::after {
                transform: translateX(0%);
            }
        }

        ::after {
            transition: transform 0.3s linear;
            content: '';
            position: absolute;
            top: -8px;
            left: -20px;
            width: 63px;
            height: 40px;
            background-color: $blue;
            z-index: 1;
            border-radius: 3px;
            transform: translateX(100%);
        }
    }

    .coveo-pager-list,
    .coveo-results-per-page-list {
        padding-left: 0;
        list-style: none;
        border-radius: 0.25rem;

        &-item {
            display: inline;
            margin: 0;
            padding: 0;
            border: 0;
            background-color: transparent;
            
            &.coveo-active .coveo-pager-list-item-text,
            &.coveo-active .coveo-results-per-page-list-item-text {
                z-index: 1;
                color: #fff;
                background-color: $blue;
                border-color: $blue;
            }
        }

        &-item > a,
        &-item-text,
        &-item-text {
            position: relative;
            padding: 6px 12px;
            margin-left: -1px;
            line-height: 1.5;
            color: $blue;
            text-decoration: none;
            background-color: #fff;
            border: 1px solid #ddd;
            font-size: 16px;

            &:hover {
                z-index: 2;
                color: #23527c;
                background-color: #eee;
                border-color: #ddd;
            }
        }

        .coveo-pager-list-item:hover, 
        .coveo-pager-list-item:hover a,
        .coveo-results-per-page-list-item:hover, 
        .coveo-results-per-page-list-item:hover a {
            text-decoration: none;
        }

        .coveo-pager-next-icon-svg, 
        .coveo-pager-previous-icon-svg {
            height: 12px;
        }
    }

    .coveo-results-per-page-text {
        font-family: $coveo_font_bold;
        color: $blue;
        margin-right: 10px;
    }
    
    .productListContent {
        //min-height: 140px;  // placeholder for spinner 

        @include media-breakpoint-between (sm, md) {
            display: flex;
            flex-wrap: wrap;
            margin-right: -15px;
            margin-left: -15px;

            .cc_product_container {
                flex: 0 0 calc(100% / 2);
                max-width: calc(100% / 2);
                margin-bottom: 30px;
                padding-right: 15px;
                padding-left: 15px;
            }
        }
    }

    /*******************************************************
    ***** Coveo Results Header *****************************
    *******************************************************/
    .filter-header {
        min-height: 60px;
    }

    @include media-breakpoint-down(sm) {
        .coveo-results-header {
            padding-left: 10px;
            -webkit-box-lines: multiple;
            -moz-box-lines: multiple;
            box-lines: multiple;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            justify-content: center;
        }
    }


    /*******************************************************
    ***** Coveo Search Box *********************************
    *******************************************************/
    &.navbar-form .input-group {
        display: inline-table;
        vertical-align: middle;
    }

    .CoveoSearchbox .magic-box {
        border: 0;
        
        .magic-box-input > input {
            border: 1px solid $coveo_border_color_default;
            transition: border-color 0.2s ease;
            color: $coveo_input_color;
        
            &:hover,
            &:focus {
                border-color: $coveo_border_color_active;
            }
        }
    }
    
    .CoveoSearchInterface {
        min-width: 100%;
        
        .CoveoSearchbox {
            margin-right: 0;
        }    
    }
        
    .magic-box .magic-box-input,
    .magic-box .magic-box-input .magic-box-underlay, 
    .magic-box .magic-box-input > input,
    .CoveoSearchButton {
        height: 40px;
        font-family: $coveo_font_regular;
        font-size: $body-font-size;
        line-height: $body-line-height;
    }
    
    .CoveoSearchButton {
        position: absolute;
        right: 0;
        width: 50px;
        border: 0;
        
        .coveo-search-button {
            display: inline-flex;
            width: 100%;
            height: 100%;
            font-family: $coveo_font_icon;
            font-size: 26px;
            color: $coveo_icon_color_default;
            transition: font-size 0.3s ease-in-out, color 0.3s ease-in-out;
            align-items: center;
            justify-content: center;

            &:hover,
            &:focus {
                color: $coveo_icon_color_active;
                font-size: 34px;
            }

            &::before {
                content: "\e9ad";
            }
        
            &-svg {
                display: none;
            }
        }
    }

    .CoveoSearchbox .magic-box .magic-box {
        &-clear {
            height: 40px;
            width: 25px;
            right: 0;
            line-height: 38px;
            background-color: transparent;
            transition: 
                right 0.3s,
                visibility 0.1s 0s,
                width 0.3s;
            z-index: 2;
    
            &:hover .magic-box-clear-svg {
                color: $coveo_icon_color_active;
                width: 12px;
                height: 12px;
            }
        }
    
        &-clear-svg {
            width: 10px;
            height: 10px;
            color: $coveo_icon_color_default;
            transition: 
                color 0.2s ease,
                width 0.2s ease,
                height 0.2s ease;
        }
    
        &-suggestions {
            font-family: $coveo_font_regular;
            left: 0;
            right: 0;
        }

    }

    .magic-box.magic-box-notEmpty .magic-box-clear {
        width: 25px;
        right: 50px;
        transition: 
            right 0.3s,
            visibility 0.1s 0.1s;
    }

    .magic-box.magic-box-hasFocus .magic-box-suggestions.magic-box-hasSuggestion .coveo-magicbox-suggestions, 
    .magic-box.magic-box-hasFocus .magic-box-suggestions.magic-box-hasSuggestion .coveo-suggestion-container {
        border: 1px solid $coveo_border_color_default;
        border-top: 0;
        box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.1);
    }

    .CoveoOmnibox.magic-box .coveo-omnibox-selectable.coveo-omnibox-selected {
        background-color: $gray-2;
    }

    .CoveoOmnibox.magic-box .coveo-omnibox-hightlight {
        font-weight: 700;
    }
    
    .CoveoOmnibox.magic-box .coveo-omnibox-hightlight2 {
        font-style: italic;
    }


    /*******************************************************
    ***** Coveo Facet Filter *******************************
    *******************************************************/

    .CoveoCategoryFacet, 
    .CoveoFacet,
    .CoveoFacetRange,
    .CoveoFacetSlider,
    .CoveoHierarchicalFacet,
    .CoveoTimespanFacet {
        margin: 0;
        border: 0;
        border-bottom: 1px solid $coveo_border_color_default;
        background-color: $white-1;
    }

    .coveo-facet {
        &-header {
            padding-left: 10px;
            padding-right: 10px;
            border: 0;
            background-color: white;
        }

        &-header-title {
            font-family: $categoryContainer-headline-active-fontfamily;
            font-size: $category-nav-headline-fontsize;
            font-weight: normal;
            line-height: $body-line-height;
            color: $blue;
        }
    }

    .coveo-slider-button, 
    .coveo-slider-button.coveo-active {
        background-color: $blue;
        transition: background-color 0.2s ease;

        &:hover,
        &:active {
            background-color: $coveo_icon_color_active;
        }
    }

    .CoveoDynamicFacet, 
    .CoveoDynamicFacetRange, 
    .CoveoDynamicHierarchicalFacet {
        background-color: $white-1;
        border-bottom: 5px solid white;
    }
    
    .coveo-dynamic-facet-collapsed, 
    .coveo-dynamic-facet-range-collapsed, 
    .coveo-dynamic-hierarchical-facet-collapsed {
        background-color: white;
        border-bottom: 1px solid $coveo_border_color_default;
    }

    .coveo-dynamic-facet {
        &-header {
            padding-left: 10px;
            padding-right: 10px;
            border: 0;
        }

        &-header-title {
            font-family: $categoryContainer-headline-active-fontfamily;
            font-size: $category-nav-headline-fontsize;
            font-weight: normal;
            line-height: $body-line-height;
            color: $blue;
        }

        &-search,
        &-values {
            margin-left: 10px;
            margin-right: 10px;
        }

        &-values {
            font-size: $body-font-size-seo;
            line-height: $body-line-height-seo;
        }

        &-value .coveo-checkbox-span-label, 
        &-value .coveo-checkbox-span-label-suffix {
            transition: opacity 0.2s ease;
        }

        &-value.coveo-selected .coveo-checkbox-span-label, 
        &-value.coveo-selected .coveo-checkbox-span-label-suffix {
            font-family: $coveo_font_bold;
            font-weight: normal;
        }
    
        &-value .coveo-checkbox-span-label-suffix {
            @extend .badge;
            font-family: $category-nav-badge-fontfamily;
            font-size: $category-nav-badge-fontsize;
            font-weight: normal;
            line-height: $category-nav-badge-lineheight;
            color: $category-nav-badge-color;
            background-color: white;
            margin-left: 10px;
        }

        &-collapse-toggle-svg {
            color: $coveo_icon_color_default;
            width: 12px;
            height: 12px;
            transition: color 0.2s ease;
        }

        &-header-btn:hover .coveo-dynamic-facet-collapse-toggle-svg {
            color: $coveo_icon_color_active;
        }
        
        &-collapsed .coveo-dynamic-facet-values,
        &-collapsed .coveo-dynamic-facet-search {
            display: none;
        }
        
        &-header-clear,
        &-show-more {
            display: inline-block;
            padding-bottom: 0;
            width: auto;
            font-family: $coveo_font_bold;
            box-shadow: inset 0 -1px $blue;
            transition: box-shadow 0.2s ease;
            text-decoration: none;
            padding-left: 0!important;
            margin-left: 40px;
            
            &:hover {
                box-shadow: inset 0 -2px $blue;
                text-decoration: none;
            }
        }

        &-header-clear {
            padding: 0;
            margin-right: 5px;
            height: 20px;
        }

        &-show-less {
            display: inline-block;
            padding-bottom: 0;
            width: auto;
            color: $blue;
            padding-left: 0!important;
            margin-left: 40px;

            &:hover {
                box-shadow: inset 0 -1px $blue;
                text-decoration: none;
            }

            &::before {
                content: "\2014";
            }
        }
    }

    .coveo-dynamic-hierarchical-facet {
        &-values {
            margin-left: 10px;
            margin-right: 10px;
        }
        
        &-collapsed .coveo-dynamic-hierarchical-facet-values {
            display: none;
        }
        
        &-value-label {
            color: $blue;    
        }
    
        &-value {
            font-size: $body-font-size;
            line-height: $body-line-height;
            transition: opacity 0.2s ease;
        }

        &-value.coveo-selected .coveo-dynamic-hierarchical-facet-value-count, 
        &-value.coveo-selected .coveo-dynamic-hierarchical-facet-value-label {
            font-family: $coveo_font_bold;
            font-weight: normal;
        }

        &-show-more {
            display: inline-block;
            padding-bottom: 0;
            width: auto;
            font-family: $coveo_font_bold;
            box-shadow: inset 0 -1px $blue;
            transition: box-shadow 0.2s ease;
            text-decoration: none;
            padding-left: 0!important;
            margin-left: 40px;
            
            &:hover {
                box-shadow: inset 0 -2px $blue;
                text-decoration: none;
            }
        }

        &-show-less {
            display: inline-block;
            padding-bottom: 0;
            width: auto;
            color: $blue;
            padding-left: 0!important;
            margin-left: 40px;

            &:hover {
                box-shadow: inset 0 -1px $blue;
                text-decoration: none;
            }

            &::before {
                content: "\2014";
            }
        }

        &-value-arrow-svg {
            color: $coveo_icon_color_default;
            transition: color 0.2s ease;
        }

        &-all:hover .coveo-dynamic-hierarchical-facet-value-arrow-svg {
            color: $coveo_icon_color_active;
        }
    }

    .coveo-dynamic-facet-value .coveo-checkbox-span-label-suffix,
    .coveo-dynamic-hierarchical-facet-value-count {
        @extend .badge;
        font-family: $category-nav-badge-fontfamily;
        font-size: $category-nav-badge-fontsize;
        font-weight: normal;
        line-height: $category-nav-badge-lineheight;
        color: $category-nav-badge-color;
        background-color: white;
        margin-left: 10px;
    }

    .coveo-checkbox-label {
        color: $blue;
    }
    
    .coveo-combobox {
        &-input input {
            border: 1px solid $coveo_border_color_default;
            transition: border-color 0.2s ease;
    
            &:hover,
            &:focus {
                border-color: $coveo_border_color_active;
            }
        }
    
        &-values {
            border: 1px solid $coveo_border_color_default;
            border-top: 0;
            box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.1);
        }
    
        &-input .coveo-combobox-input-icon {
            left: auto;
            right: 5px;
            z-index: 2;
            margin: 0;
            font-family: $coveo_font_icon;
            font-size: 26px;
            color: $coveo_icon_color_default;
            transition: color 0.3s ease-in-out;
            align-items: center;
            justify-content: center;
    
            &::before {
                content: "\e9ad";
            }
    
            svg {
                display: none;
            }
            
            & + input {
                padding-left: 5px;
                padding-right: 35px;
            }
        }
        
        &-wait-animation {
            background: white;
            z-index: 3;
        }
    }
}

.coveo-card-layout .product-tile__pricebox__price {
    margin-bottom: 20px;
}

.coveo-result-list-container.coveo-card-layout-container .coveo-card-layout.CoveoResult {
    border: 0;
}

.coveo-card-layout.CoveoResult p.product-tile__name > a.CoveoResultLink {
    font-size: 18px;
    line-height: 24px;
}

.coveo-card-layout.CoveoResult p.product-tile__name {
    min-height: 50px;
    max-height: 50px;
    overflow: visible;
}

.coveo-card-layout.CoveoResult {
    & .product-tile {
        & > .row {
            @include media-breakpoint-between (sm, md) {
                min-height: 375px;
                align-items: end;
            }
        }
    }
}

.coveo-add-to-cart{
    &.disabled{
        cursor: not-allowed;
    }
}