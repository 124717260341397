.myAccountNavigation {
    padding-top: 20px;

    &__header {
        margin-bottom: 20px;
    }

    &__listItem {
        height: 40px;
        width: 100%;
        display: flex;
        border-bottom: 1px solid $gray-2;

        &:first-of-type {
            border-top: 1px solid $gray-2;
        }
    }

    &__link {
        height: 100%;
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: bold;
    }
}