.mt-10 {
  margin-top: 10px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-7 {
  margin-top: 7px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mt--20 {
  margin-top: -20px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-md--40 {
  margin-top: -40px !important;
  
  @include media-breakpoint-down(md) {
    margin-top: -20px !important;
  }
}

.mt-60 {
  margin-top: 60px !important;
}

.my-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.opa-3 {
  opacity: 0.3;
}

@include media-breakpoint-up(md) {
  .my-md-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
}

.px-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.px-60 {
  padding-left: 60px !important;
  padding-right: 60px !important;
}

.py-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.py-60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}

.hdm-bg-primary {
  background-color: $white-2;
}

.hdm-bg-secondary {
  background-color: $white-1;
}

.hdm-bg-tertiary {
  background-color: $gray-2;
}

.no-hover {
  &:focus {
    text-decoration: none;
  }
}

// You want to limit exactly 3 lines in a container with '...'
.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
}

.line-clamp-md-3 {
  @include media-breakpoint-up(md) {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

.h-md-100 {
  @include media-breakpoint-up(md) {
      height: 100%;
  }
}

.w-lg-100 {
  @include media-breakpoint-up(lg) {
      width: 100%;
  }
}

.w-xl-100 {
  @include media-breakpoint-up(xl) {
      width: 100%;
  }
}

.position-right-zero {
    right: 0px;
}

.position-left-zero {
  left: 0px;
}

.pr-10 {
  padding-right: 10px !important;
}

.hyphens-auto {
  hyphens: auto;
}

.w-345 {
  width: 345px;
}

.pt-20 {
  padding-top: 20px !important;
}