@media print {
    a[href]:after {
      content: none !important;
    }

    .prefooter, 
    .footer-ds, 
    .cartPage__actionButtons, 
    .mobileNavigation,
    .cartPage__cuponBox__wrapper,
    .overviewBox__buttonBox {
        display: none !important;
        height: 0;
    }

  .stickyBarAnchor {
    display: none !important;
  }
  
  header {
    position: static !important;
    height: 0 !important;
  }
  
  div.header.cc_header {
    position: absolute !important;
  }
  
  div.mobileNavigationBarInclude > div.mobileNavigation {
    position: static !important;
  }
}