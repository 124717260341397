.viewPortShower {
    position: absolute;
    top: 38px;
    left: 255px;

    @if not($enableDev) {
        display: none;
    }

    @include media-breakpoint-down(sm) {
        left: 170px;
        top: 18px;
    
    }

    p {
        color: $gray-20;
        font-family: $body-font-family-serif;
    }
}