/*--------------------- Config flags ---------------------*/
/*--------------------------------------------------------*/
$enableDev: false;       // e.g. display viewport size near to Heidelberg logo


/*-------------------- Color Variables -------------------*/
/*--------------------------------------------------------*/

// Primary Colors
$blue:      #004178;
$cyan:      #00a0e6;
$green:     #009b3c;
$yellow:    #ffc800;
$white-1:   #f3f4f8;
$white-2:   #ffffff;
$white-3:   rgba(255,255,255,0.7);

// Secondary Colors
$gradient:  linear-gradient(to left,#ffc800 0, #009b3c 33%, #00a0e6 66%, #004078 100%);
$gray-1:    #e8e9ee;
$gray-2:    #d3d7da;

// Gradations
$gray-20:   #7d838b;
$gray-40:   #a8b1b6;
$gray-60:   #60656b;
$gray-80:   #414549;

// Accent colors
 $orange-1: #f5501e;



/*---------------------- Typography ----------------------*/
/*--------------------------------------------------------*/

$headline-font-family-regular:  'Heidelberg Gothic-10';
$headline-font-family-bold:     'Heidelberg Gothic-18';

$body-font-family-regular: 'Heidelberg Gothic-10';
$body-font-family-serif:  'Heidelberg Antiqua-10';

$headline-h1-font-size:     32px;
$headline-h2-font-size:     40px;
$headline-h3-font-size:     24px;
$headline-h4-font-size:     20px;
$headline-h5-font-size:     18px;

$headline-h1-line-height:   38px;
$headline-h2-line-height:   48px;
$headline-h3-line-height:   29px;
$headline-h4-line-height:   24px;
$headline-h5-line-height:   24px;

$body-font-size:            16px;
$body-line-height:          24px;

$body-font-size-seo:        14px;
$body-line-height-seo:      21px;

$body-font-size-small:      12px;
$body-line-height-small:    14px;

// Breadcrumbs
$breadcrumb-fontsize:       14px;
$breadcrumb-lineheight:     21px;

// Category navigation
$categoryContainer-headline-fontsize:           $headline-h3-font-size;
$categoryContainer-headline-lineheight:         $headline-h3-line-height;
$categoryContainer-headline-color:              $blue;
$categoryContainer-headline-active-fontfamily:  $headline-font-family-bold;
//is needed, because some languages are cuttingupper or lower content of some letters in viewports md and higher -> 60px, because of Linux and MacOS system fitting.
$categoryHeaderBannerLineHeight:                60px;
$categoryContainer-collapse-bc:                 $white-1;

$category-nav-headline-fontsize:                18px;
$category-nav-headline-lineheight:              19px;
$category-nav-headline-color:                   $blue;

$category-nav-badge-color:                      $blue;
$category-nav-badge-bc:                         $white-1;
$category-nav-badge-fontfamily:                 $body-font-family-regular;
$category-nav-badge-fontsize:                   $body-font-size-small;
$category-nav-badge-lineheight:                 $body-line-height-small;

// category filter
$category-filter-filled-bc:                     $white-1;



/*------------------------ z-index -----------------------*/
/*--------------------------------------------------------*/
$zindex-modal-layer:              1003;
$zindex-modal-bg:                 1002;
$zindex-header:                   1001;
$zindex-sticky-toolbar:           1000;
$zindex-sticky-toolbar-mobile:    1000;
$zindex-ccc-btn:                   999;
$zindex-header-menu:               900;
$zindex-header-btn-close:          101;



/*---------------- dimensions and distances --------------*/
/*--------------------------------------------------------*/
$navigation-mobile-height: 50px;
