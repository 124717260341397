.teaser-large-firstheader {
    @extend .slider-firstheader;
    
    .teaser-large-container & {
        font-size: 32px;
        line-height: 1.2;
    }
}

.teaser-large-secondheader {
    @extend .slider-secondheader;
    
    .teaser-large-container & {
        font-size: 32px;
        line-height: 1.2;
    }
}