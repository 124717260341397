.header {
    &__navigationHeader {
        background-color: $gray-1;
        font-size: $body-font-size-seo;
        line-height: $body-line-height-seo;

        @include media-breakpoint-up(md) {
            height: 5px;
        }

        a {
            display: inline-block;
            color: $gray-60;

            @include media-breakpoint-up(md) {
                background-color: $gray-1;
                border-radius: 0 0 3px 3px;
            }
        }


        .active {
            background-color: white;
            border-radius: 3px 3px 0 0;

            @include media-breakpoint-up(md) {
                margin: -3px;
            }
        }
    }

    &__navigationMain {
        height: calc(100% - 110px); // Use height as a fallback for browsers that do not support Custom Properties
        height: calc(var(--vh, 1vh) * 100 - 175px);
        background-color: white;
        max-width: 1560px;
        overflow-y: auto;

        @include media-breakpoint-up(md) {
            margin-left: auto;
            margin-right: auto;
            position: static;
            height: auto;
            overflow-y: visible;
        }
    }

    &__navigationMainContent {
        @include media-breakpoint-up(md) {
            & > .header__subNavigationPoint {
                margin-right: 70px;

                &:first-child {
                    margin-left: -10px;
                }
            }
        }
    }

    &__subNavigation {
        position: absolute;
        right: -100%;
        top: 0;
        z-index: 1000;
        width: 100%;
        height: 100vh;
        background-color:white;
        transition: right .2s;

        @include media-breakpoint-up(md) {
            right: 0;
            height: auto;
            background-color: $white-1;
        }
    }

    &__productCategoryList {
        top: 0;

        @include media-breakpoint-up(md) {
            display: block;
            position: absolute;
            overflow: auto;
            top: 100%;
            left: 0;
            z-index: -1;
            width: 100vw;
            padding: 0 calc(50vw - 790px);
            max-height: calc(100vh - 187px);    // 187px is the height of header menu

            & > .header__subNavigationPoint {
                display: block;
                float: left;
                width: 25%;
                position: relative;

                & > a,
                & > .link {
                    font-size: 20px;
                    font-weight: bold;
                }

                &.jqhover > a,
                &.jqhover > .link {
                    text-decoration: underline;
                    color: $cyan;
                }

                & > .headline,
                & > .headline:hover {
                    text-decoration: none;
                    color: $cyan;
                }
            }
        }
    }

    &__productList {
        @include media-breakpoint-up(md) {
            display: block;
            position: relative;
            
            &.show-columns {
                column-count: 2;
                column-width: 110px;
            }

            & > .header__subNavigationPoint {
                display: block;
            }
        }
    }

    &__subNavigationPoint {
        overflow: hidden;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &.jqhover {
            & > .cc_category {
                color: $cyan;
            }

            & > .header__subMenuImg::before {
                transform: scale(1.2);
            }
        }

        @include media-breakpoint-up(md) {
            display: inline-block;
            overflow: visible;
            margin-right: -5px;
        }

        a {
            float: left;
            padding-left: 15px;

            @include media-breakpoint-up(md) {
                float: none;
                padding-left: 0;

                &:hover {
                    text-decoration: none;
                }
            }
        }

        i {
            float: right;
            margin-right: 15px;
        }
    }

    .cc_category {
        @include media-breakpoint-up (md) {
            padding-bottom: 15px;
        }
    }

    &__subNavigationHeader {
        & > a {
            position: relative;

            @include media-breakpoint-up(md) {
                &::before {
                    content: "";
                    height: 0;
                    width: 100%;
                    display: block;
                    background-color: $gray-2;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    transition: height 0.2s;
                }

                &:hover {
                    color: $cyan;

                    &::before {
                        height: 3px;
                    }
                }
            }
        }
    }

    &__navigationFooter {
        a:hover {
            text-decoration: none;
        }
        
        @include media-breakpoint-up(md) {
            width: 220px;
            position: absolute;
            right: 30px;
            top: 0;
        }
        
        @include media-breakpoint-up(xl) {
            right: calc(50vw - 790px);
        }
    }
    
    &__languageAnchor {
        i::before {
            color: $gray-20;
        }
    }

    &__hasSubmenu > a {
        @include media-breakpoint-down(sm) {
            pointer-events: none;
        }
    }
}

.navbar-nav a {
    font-family: $body-font-family-regular;
    font-size: $body-font-size-seo;
    line-height: $body-line-height-seo;
    color: $gray-20;
}

.nav-pills > li {
    margin-left: 0 !important;
    width: 20%;
}