.table {
    & > thead,
    & > tbody,
    & > tfoot {
        tr.active > td,
        tr.active > th,
        tr > td.active,
        tr > th.active {
            background-color: $blue;
            color: white;
        }
    }
}

.table-hover > tbody {
    & > tr.active:hover > td, 
    & > tr.active:hover > th,
    & > tr:hover > .active,
    & > tr > td.active:hover, 
    & > tr > th.active:hover {
        background-color: $cyan;
        color: white;
    }

    & > tr:hover {
        background-color: $gray-2;
    }
}
