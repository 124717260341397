.cc_results_list {
  overflow: hidden;
}

.list-position-right{
  right: 15px;
}

.grid-position-right{
  right: 0;
  
  @include media-breakpoint-down(md) {
    right: 15px;
  }
}

.product {
  &__promo {
    display: inline-block;
    box-sizing: border-box;
    text-align: center;
    height: 30px;
    padding: 4px 20px 0px;
    min-width: 50px;
    color: #ffffff;
    font-family: $headline-font-family-bold;
    font-size: 16px;
    background-color: #f5501e;
    text-decoration: none;

    &__second{
      margin-top: 3px;
    }

    &-wrapper {
      position: absolute;
      z-index: 2;
    }

    &__text {
      line-height: 30px;
      height: 30px;
      overflow: hidden;
      text-align: left;
    }
    
    &-listView {
      z-index: 100;
      border-bottom-right-radius: 3px;
      position: absolute;
      top: -20px;
    }
  }
  
  &__badgeBox {
    position: absolute;
    top: 0;

    &--listView {
      z-index: 950;
    }

    i {
      height: 27px;
      width: 27px;
      border-radius: 50%;
      color: $white-2;

      &::before {
        text-align: center;
        display: block;
        color: inherit;
        font-size: 24px;
        line-height: 28px;
      }
    }

    .icon-eco {
      background-color: $green;
    }

    .icon-repair {
      background-color: $cyan;
    }

    .icon-service_technican {
      background-color: $yellow;
      color: $blue;
    }
  }

  &__totals {
    &__price {
      font-size: 24px;
      line-height: 32px;
      line-break: normal;
      word-break: break-all;
      word-break: break-word;
    }
  }

  &__availibility {
    display: flex;
    align-items: center;

    .trafficLight {
      display: inline-block;
      height: 14px;
      width: 14px;
      border-radius: 50%;
      margin-right: 5px;
    }

    &.Availability {
      .trafficLight {
        background-color: $green;
      }
    }

    &.NoAvailability {
      .trafficLight {
        background-color: $red;
      }
    }

    &.SoonNoAvailability {
      .trafficLight {
        background-color: $yellow;
      }
    }
  }
}

.product-tile {
  width: 100%;
  height: 100%;
  border: 1px solid #d3d7da;
  background-color: #ffffff;
  background-size: cover;
  text-align: center;
  font-family: $body-font-family-regular;
  margin-bottom: 15px;

  .img-wrapper {
    min-height: 180px;
  }

  & > .row {
    position: relative;
    margin: 20px 0 0 15px;

    @include media-breakpoint-between (sm, md) {
      min-height: 530px;
      align-items: end;
    }
  }

  &__pictureFrame {
    height: 180px;
  }

  &__image {    
    width: 100%;
    max-width: 150px;
    max-height: 150px;
    position: relative;
    transition: transform 0.3s;
  }

  &__descBox {
    min-height: 80px;
    justify-content: space-between;
    display: flex;
    flex-flow: column;

    @include media-breakpoint-up(md) {
      padding: 0 35px;
    }
  }

  &__name {
    color: $blue;
    font-family: $body-font-family-regular;
    z-index: 100;
    position: relative;
  }

  &__SKU {
    color: $gray-20;
    font-size: 14px;
  }

  &__regular-price {
    color: $blue;
    text-decoration: line-through;
  }

  &__pricebox {
    display: inline-block;

    &__price {
      font-size: 24px;
      font-family: $headline-font-family-bold;
      color: $blue;
    }

    &__unit {
      color: $gray-20;
    }
  }

  &__variants {
    color: $gray-20;
  }

  &__reward-points-box {
    &__medal {
      display: inline-block;
      width: 35px;
      height: 35px;

      @include media-breakpoint-up(lg) {
        margin-left: -10px;
      }
    }

    &__text {
      display: inline-block;
      color: $blue;
      font-size: 16px;
    }
  }

  &__quantity-picker-box {
    .row {
      margin-right: 0;
      margin-left: 0;
    }

    .cc_qty_control_row {
      display: inline-block;
      width: 90px;
    }
  }

  .btn {
    max-width: 260px;
  }
}

.cc_grid_container {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;

  .cc_product_container {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 30px;

    &:hover .product-tile__image {
      transform: scale(1.1);
      z-index: 50;
    }

    @include media-breakpoint-up (sm) {
      flex: 0 0 calc(100% / 2);
      max-width: calc(100% / 2);
    }

    @include media-breakpoint-up (lg) {
      flex: 0 0 calc(100% / 3);
      max-width: calc(100% / 3);
    }

    @include media-breakpoint-up (xl) {
      flex: 0 0 calc(100% / 4);
      max-width: calc(100% / 4);
    }
  }
}

.cc_results_list:not(.cc_grid_container) {
  .cc_product_container {
  @include media-breakpoint-between (sm, md) {
      width: calc(50% - 15px);
      display: block;
      float: left;
  
      &:nth-of-type(odd) {
        margin-right: 30px;
      }
    }
  }
}

.cc_grid_container {
	.product__promo-listView {
		left: 0;
	}
}