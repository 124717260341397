.rigister-user-background-color { 
    background-color: $cyan;
}

.rfq-select-form-control-wrapper::before {
    content: "";
    position: absolute;
    display: block;
    height: 9px;
    width: 9px;
    border: solid #7d838b;
    border-width: 0 1px 1px 0;
    top: 9px;
    right: 14px;
    transform: rotate(45deg);
    pointer-events: none;
    
}

.rfq-select-form-control-wrapper {
    height: 33px;
    position: relative;
    border: 1px solid #d3d7da;
    border-radius: 3px;
    transition: border-color .2s;

}

.label-font-color {
    color: $white;
}

.body-min-heigth {
    @include media-breakpoint-up(md) {
        min-height: 550px;
    }
}

.rfq-continue-shopping-btn {
    @include media-breakpoint-up(lg) {
        position: absolute;
        bottom:0 ;
        right: 0;
        left: 0;
        width: 85%;
    }
}

.check-form-sm {
    display: flex;

    .check-form-input {
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        width: 15px;
        height: 15px;
        border: 1px solid $gray-2;
        border-radius: 3px;
        transition: border 0.2s;
        background-color: $white-2;
        position: relative;
        margin: 0 15px 0 0;
        display: inline-block;

        &:hover {
            border-color: $blue;
            cursor: pointer;
        }

        &:checked::before {
            content: "";
            display: block;
            height: 5px;
            width: 9px;
            border: solid $blue;
            border-width: 0 0 1.5px 1.5px;
            transform: rotate(-45deg);
            position: absolute;
            top: 3px;
            left: 2px;
        }
    }
}