
.product-slider {
    overflow: hidden;
    
    &__frame {
        padding: 0 15px;
    }

    .headline {
        font-family: $headline-font-family-bold;
        font-size: $headline-h1-font-size;
        line-height: $headline-h1-line-height;
        margin: 0 auto 30px auto;
        text-align: center;
        color: $blue;
    }
    
    .badge-icon-position-right {
        right: 15px;
    }
}