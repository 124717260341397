.orderHistory {
    background-color: $white-1;
    padding: 20px;

    p {
        margin-bottom: 0;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    a {
        &:hover {
            color: $blue;
        }
    }

    .pageAction i {
        font-size: 24px;
    }

    &__tableHeader {
        @include media-breakpoint-up(lg) {
            margin-bottom: 20px;
        }

        .col-12 {
            margin-bottom: 20px;

            @include media-breakpoint-up(lg) {
                margin-bottom: 0;
            }
        }
    }

    &__listHeader {
        background-color: $gray-2;
    }

    &__listRow {
        border-top: 1px solid $gray-2;

        @include media-breakpoint-up(lg) {
            border-top: none;
            border-bottom: 1px solid $gray-2;
        }

        .row {
            @include media-breakpoint-up(lg) {
                min-height: 40px;
            }
        }

        .col-6 {
            margin-bottom: 10px;

            @include media-breakpoint-up(lg) {
                margin-bottom: 0;
            }
        }

        @include media-breakpoint-down(md) {
            font-size: $body-font-size-seo;

            [class^="col-"] {

                @include media-breakpoint-up(md) {
                    line-height: 0;
                }
                
                p {
                    line-height: 1.5;
                }
            }
        }
    }

    &__status {
        @include media-breakpoint-up(lg) {
            font-family: $headline-font-family-bold;
        }
    }

    &__itemsPerPage-bottom {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
    }

    @include media-breakpoint-down(md) {
        &__orderNmbr {
            font-family: $headline-font-family-bold;
            font-size: $body-font-size;
            line-height: $body-line-height;
        }

        &__detailsLnk {
            position: absolute;
            right: -10px;
            top: 0;
            bottom: 0;
            margin: auto;
            height: 34px;
            width: 34px;

            a {
                display: block;
                box-shadow: none;
    
                &:hover,
                &:focus {
                    box-shadow: none;
                }
            }
            
            a:hover i {
                color: $cyan;
            }
        }
    }
}

.orderHistoryDetails .table th {
    width: 50%;
}
