.navbar-form {
    margin: 0;
    padding: 0;

    .input-group,
    .input-group-btn {
        width: 100%;
        height: 40px;

        .btn {
            position: absolute;
            right: 0;
            height: 40px;
        }

        .icon-search {
            font-size: 26px;
            line-height: 34px;;

            &::before {                
                color: $gray-20;
            }
        }
    }

    .search_input {
        height: 40px;
        width: 100% !important;
    }
}

.form-control {
    &:focus,
    &:hover {
        border-color: $blue;
        box-shadow: none;
    }
}

.select-form-control-wrapper {
    position: relative;
    border: none;
    border-radius: 3px;
    transition: border-color .2s;
    height: 40px;
    margin-bottom: 10px;

    &:hover,
    &:focus {
        border-color: $blue;
        box-shadow: transparent;
    }

    &::before {
        content: "";
        position: absolute;
        display: block;
        height: 9px;
        width: 9px;
        border: solid $gray-20;
        border-width: 0 1px 1px 0;
        top: 12px;
        right: 14px;
        transform: rotate(45deg);
        pointer-events: none;
    }
}

.select-form-control {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border: 1px solid $gray-2;;
    height: 100%;
    width: 100%;
    font-size: 14px !important;
    padding-left: 10px;
    padding-right: 30px;
    cursor: pointer;

    &:hover,
    &:focus {        
        box-shadow: none;
    }

    &::-ms-expand{
        display: none;
    }

    &.text-large {
        font-size: 16px !important;
    }
    &__input {    
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        border: none;
        height: 100%;
        width: 100%;
        font-size: 14px !important;
        cursor: pointer;

        
        &::-webkit-calendar-picker-indicator {
            display: none;
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }
    &__datalist{
        background-color: #bbb;
        padding: 4px;
        margin-bottom: 1px;
        cursor: pointer;
    }
}

.select-form-control > option:disabled {
    color: $gray-HDM;
}


.input-form {
    width: 100%;
    border: 1px solid $gray-2;
    height: 40px;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 20px;

    &.placeholderVisible {
        &::placeholder,
        &::-webkit-input-placeholder,
        &:-ms-input-placeholder {
            opacity: 1;
        }
    }    
}

.check-form {
    display: flex;

    .check-form-input {
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        width: 22px;
        height: 22px;
        border: 1px solid $gray-2;
        border-radius: 3px;
        transition: border .2s;
        background-color: $white-2;
        position: relative;
        margin: 0 15px 0 0;
        display: inline-block;

        &:hover {
            border-color: $blue;
            cursor: pointer;
        }

        &:checked::before {
            content: "";
            display: block;
            height: 6px;
            width: 10px;
            border: solid $blue;
            border-width: 0 0 1.5px 1.5px;
            transform: rotate(-45deg);
            position: absolute;
            top: 5px;
            left: 5px;
        }
    }

    .check-form-label {        
        width: calc(100% - 40px);
        display: inline-block;
    }

    input + label {
        cursor: pointer;
    }
}

.form-group:last-child {
    margin-bottom: 0;
}

.form-group-toggle {
    height: 40px;
    border-radius: 3px;
    overflow: hidden;
    position: relative;

    .btn {
        width: 50%;
        color: $gray-20;
        background-color: $white-2;
        border: none;
        border-radius: 0;
        height: 100%;
        opacity: 1;
        display: inline-flex;
        justify-content: center;
        align-items: center;

        i {
            color: $blue;
        }

        &:last-of-type {
            margin-left: -5px;
        }

        &.active {
            background-color: $blue;
            color: $white-2;

            i {
                color: $white-2;
            }

            &:hover {
                background-color: $blue;
                color: $white-2;                
            }
        }

        &:hover {
            background-color: $white-2;
            color: $gray-20;
        }
    }
}

.radio-form {
    label {
        display: flex;
        min-height: 40px;
        border: 1px solid $gray-2;
        background-color: $white-2;
        padding: 5px 10px;
        margin-bottom: 10px;
        border-radius: 3px;
        position: relative;
        font-size: 14px;
        transition: border-color .2s;
        cursor: pointer;
        -webkit-column-break-inside: avoid;
        page-break-inside: avoid;
        break-inside: avoid;
        align-items: center;

        &::before {
            content: "";
            height: 20px;
            width: 20px;
            display: block;
            position: absolute;
            top: 9px;
            border-radius: 50%;
            border: 1px solid $gray-2;
        }

        &:hover {
            border-color: $blue;
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    input {
        display: none;

        &:checked + label::after {
            content: "";
            background-color: $blue;
            height: 12px;
            width: 12px;
            display: block;
            top: 13px;
            position: absolute;
            left: 14px;
            border-radius: 50%;
        }
    }

    p {
        display: inline-block;
        margin-left: 40px;
        margin-bottom: 0;
    }
}

input[type=checkbox]:focus, 
input[type=file]:focus, 
input[type=radio]:focus {
    outline: 0;
}

.ext-chrome input[type=email],
.ext-chrome input[type=text] {
    line-height: 3 !important;     // bugfix weil die Punkte der Umlaute [nur in Chrome] nicht angzeigt werden. "Äpfel" wird als "Apfel" dargestellt. Problem ist die verwendete Schriftart in kombination mit Schriftgröße. Alternativ kann auch gern eine andere Schrift verwendet werden.
}
