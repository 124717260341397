.four-teaser-tile-container {
  padding-bottom: 40px;
  padding-left: 15px;
  padding-right: 15px;
}

.four-teaser-tile-component {
  width: 100%;
  height: 260px;
  margin-bottom: 20px;
}
.four-teaser-tile-content-container{
  padding: 0;
}
.four-teaser-tile-header {
  margin: 60px 0 30px 0;
  line-height: 38px;
  color: $blue;
  font-family: $headline-font-family-bold;
  font-size: $headline-h1-font-size;
}

.four-teaser-tile-background-image {
  width: 100%;
  height: 260px;
  border-radius: 0;
  background-size: cover;
  overflow: hidden;
}

.four-teaser-tile-content {
  min-width: 100%;
  max-width: 355px;
  z-index: 1;
  background-color: transparentize($blue, 0.1);
  color: $white-2;
  max-height: 100%;

  &:hover,
  &:focus,
  &:active,
  & {
    color: $white;
    text-decoration: none;
  }
}

.four-teaser-tile-subtitle {
  margin-bottom: 10px;
  margin-left: 5px;
  margin-top: 10px;
  text-overflow: ellipsis;
  font-family: $headline-font-family-bold;
  font-size: $headline-h4-font-size;
  overflow: hidden;
  white-space: nowrap;
}

.four-teaser-tile-content-text {
  margin: 0;
  margin-left: 5px;
  height: 142px;
  max-height: 0;
  overflow-y: auto;
  color: $white-2;
  font-size: $body-font-size;
  opacity: 0;
  transition: max-height 0.5s, opacity 0.5s linear;
}

.card-body {
  &:hover {
    .four-teaser-tile-content-text {
      max-height: 177px;
      margin-bottom: 20px;
      opacity: 1;
      font-family: $body-font-family-regular;
      font-size: $body-font-size;
    }
  }
}

.four-teaser-tile-link-icon {
  width: 20px;
  height: 20px;
  margin-bottom: 10px;
  margin-left: 5px;
  border-radius: 50%;
  font-size: 20px;
  background-color: $white-2;
  color: $blue;
}

.four-teaser-tile-link {
  margin-bottom: 10px;
  text-decoration: none;
  color: $white-2;
  font-size: $body-font-size;
  font-family: $headline-font-family-bold;
  box-shadow: 0 0 0 0 $white-2;
  transition: box-shadow 0.2s;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:hover {
    color: $white-2;
    text-decoration: none;
    box-shadow: 0 0 3px 0 $white-2;
    font-family: $headline-font-family-bold;
    font-size: $body-font-size;
  }
}

.card-body:hover .four-teaser-tile-link {
  color: $white-2;
  font-size: $body-font-size;
  text-decoration: none;
  box-shadow: 0 2px 0 0 $white-2;
}

.teaser-tile-background-image {
  width: 300px;
  height: 295px;
}

/*scrollbar-settings */

/* width */
.four-teaser-tile-content-text::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.four-teaser-tile-content-text::-webkit-scrollbar-track {
  background-color: $blue;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: $cyan;
}

.gjs-dashed .TeaserTile::before {
  content: "set the trait settings";
  color: red;
  height: 15px;
}
@include media-breakpoint-down(xs) {
  .four-teaser-tile-component {
    height: 260px;
  }

  .four-teaser-tile-container {
    padding-bottom: 10px;
  }

  .four-teaser-tile-content {
    padding: 10px;
  }

  .four-teaser-tile-content-text {
    height: 142px;
    margin-left: 0;
  }

  .four-teaser-tile-header {
    margin-top: 20px;
    margin-bottom: 12px;
    font-size: $headline-h3-font-size;
  }

  .four-teaser-tile-background-image {
    height: 260px;
  }

  .four-teaser-tile-link-icon {
    margin-left: 0;
  }

  .four-teaser-tile-subtitle {
    margin-left: 0;
  }
}
