.mobileNavigation {
    display: flex;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: $zindex-sticky-toolbar-mobile;
    background-color: $white-2;
    height: $navigation-mobile-height;
    align-items: center;
    text-align: center;
    box-shadow: 0px 1px 10px rgba(0,0,0,0.1);

    // if you edit this media query, please take a look at .modal, here we're react of this sticky element
    // it has overlapped the modal layer
    @media only screen and (orientation: landscape) and (min-height: 767px) {
        display: none;
    }

    &__iconBox {
        display: inline-flex;
        width: calc(100% / 5);
        justify-content: center;

        & > i {
            height: 38px;
            width: 38px;
            display: flex;
            border-radius: 50%;
        }

        i {
            &::before {
                color: $gray-20;
            }
        }

        &--blue {
            & > i {
                background-color: $cyan;

                &::before {
                    color: $white-2;
                    height: 100%;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }

        &.toggled {
            .mobileNavigation__flyout {
                left: 0;
            }
        }
    }

    &__flyout {
        display: block;
        height: 100vh;
        width: 100vw;
        position: absolute;
        background-color: $white-2;
        left: 100%;
        transition: left .2s;
        bottom: 0;

        &__wrapper {
            position: relative;
            height: calc(100% - 110px);
            top: 110px;

            .btn {
                position: absolute;
                width: calc(100% - 30px);
                bottom: 80px;
            }
        }
        
        &__bottom {
            height: 80px;
            display: flex;            
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            margin-left: -15px;
            margin-right: -15px;
            background-color: $white-1;
            position: absolute;
            width: 100%;
            bottom: 0;

            &--twoLines {                
                align-items: flex-start;
            }

            p {                
                flex: 0 0 100%;
            }
        }
    }

    &__flyoutClose {
        position: absolute;
        right: 15px;
        top: 110px;
        z-index: 1000;
    }

    &__contactFlyout {
        text-align: left;

        img {
            margin-left: -10px;
        }

        form {
            margin-top: 20px;
            height: calc(100% - 140px);
        }

        textarea {
            resize: none;
            height: calc(100% - 195px);

            &.small {
                height: calc(100% - 285px);
            }
        }

        button {
            width: 110px;
        }

        .select-form-control {
            padding-left: 5px;
        }

        .check-form-input {
            width: 18px;
            height: 18px;

            &:checked::before {
                height: 5px;
                width: 9px;
                top: 4px;
                left: 4px;
            }
        }

        .check-form-label {
            font-size: 14px;
            line-height: 21px;
            text-decoration: underline;
        }
    }

    &__callInfo {
        margin-top: 25px;

        i {
            &::before {
                color: $blue;
            }
        }
    }

    &__myAccountFlyout {
        .icon-certificate {
            &::before {
                color: $blue;
            }
        }
    }

    &__myList {
        &__wrapper {
            height: calc(100% - 25px);
            padding: 0;
        }

        &__list {
            height: calc(100% - 120px);
            max-height: initial;
        }

        &__buttonBox {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;

            .btn {
                position: relative;
                width: initial;
                bottom: 0;
            }
        }
    }
}