.cartContainer {    
    padding-top: 20px;

    @include media-breakpoint-up(md) {
        padding-top: 30px;
    }
}

.cartPage {
    &__toBasketButton {        
        color: $white-1;
        display: inline-flex;
        height: 40px;
        width: 260px;
        position: fixed;
        bottom: -50px;
        z-index: 1000;
        background-color: $cyan;
        left: calc(50% - 130px);
        border-radius: 20px;
        justify-content: center;
        align-items: center;
        transition: bottom .2s;

        i:before {
            color: $white-1;
        }

        &.toggled {
            bottom: 60px;
        }
    }

    &__sideBox {
        .row {
            @include media-breakpoint-up(xl) {
                position: sticky;
                right: 0;
                top: 190px;
            }
        }
    }

    &__list {
        background-color: $white-1;
        padding-top: 20px;
        padding-bottom: 20px;

        &__toggleIcon {
            right: 35px;
            position: absolute;
            transition: transform .2s;

            &:not(.collapsed) {
                transform: rotate(180deg);
            }

            &:not(.collapsed) ~ .collapseHide {
                display: none;
            }
        }
        
        &__headline {
            border-bottom: 1px solid $gray-2;
        }
        
        &__header {
            background-color: $gray-2;
        }
    
        &__item {
            border-bottom: 1px solid $gray-2;
    
            &:first-of-type {
                border-top: 1px solid $gray-2;
    
                @include media-breakpoint-up(md) {
                    border-top: none;
                }
            }
        }

        &__footer {
            @include media-breakpoint-up(md) {
                padding-right: 85px;
            }
        }

        &__img {
            img {
                max-height: 80px;
                max-width: 80px;
                width: 100%;
                height: 100%;
            }
        }

        &__quantityError {
            @include media-breakpoint-up(lg) {
                position: absolute;
                width: 400px;
            }
        }

        &__mainInfoBox {
            @include media-breakpoint-up(md) {
                height: 100%;
        
                &Core {
                    display: flex;
                    flex-wrap: wrap;
                    height: 100%;
                    align-content: space-between;
                }
            }
        }

        &__productName {
            width: 100%;
        }
    }

    &__actionButtons {
        button {
            margin-left: 0;
            margin-right: 0;

            @include media-breakpoint-up(md) {
                margin-right: 30px;
            }
        }
    }   

    &__quantityBox {
        .form-group {
            max-width: 70px;
        }
    }

    &__cuponBox {
        &__button {
            min-width: 120px;
        }
        &__group {
            margin-bottom: 15px;
        }
        &__input {
            height: 40px;
            input {
                height: 100%;
            }
        }
    }

    .updateCartButton.disabled:hover {
        cursor: not-allowed;
    }
    
    .updateCartButton.disabled > i.icon-refresh:hover {
        cursor: not-allowed;
    }
}
.modal-free-product-variant .modal-body {
    margin: 0 0 20px;
    max-height:  calc(100vh - 180px);
}