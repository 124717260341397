.hdm-pantone-manager-header {
    @include media-breakpoint-up(xl) {margin-left: -5px;}
}

#zoovu-assistant {
    .pages-navigation-wrapper .navigation-back-button, 
    .pages-navigation-wrapper .navigation-next-button, 
    .start-over-button,
    .go-to-product-button, 
    .add-to-cart-button {
        height: 40px;
        font-family: $body-font-family-regular;
        font-size: 16px;
        line-height: 18px;
        color: white;
        text-transform: none;
        border-radius: 0.3rem;
        border-color: transparent;
        background-color: $blue;
        transition: color 0.15s ease-in-out, 
                    background-color 0.15s ease-in-out, 
                    border-color 0.15s ease-in-out, 
                    box-shadow 0.15s ease-in-out;

        &:hover {
            background-color: $cyan;
        }
    }

    .product-details .product-compare-wrapper label,
    .product-details .product-compare-wrapper label:hover {
        border-color: transparent;
    }

    .answers-wrapper .answer label {
        padding: 0;
    }

    .search__input {
        width: 330px;
        height: 40px;
        transition: border-color 0.15s ease-in-out;
        border-color: $gray-2;

        &:hover,
        &:focus {
            border: 1px solid $blue;
            outline: 0;
        }
    }

    .search__button {
        height: 40px;
        line-height: 38px;
        border-color: $gray-2;

        .search__icon svg {
            margin-bottom: 0px;
        }
    }

    .sort-wrapper .sort-select {
        border-radius: 2px 0 0 2px;
        transition: border-color 0.15s ease-in-out;

        &:hover,
        &:focus {
            border: 1px solid $blue;
            outline: 0;
        }
    }

    .sort-wrapper .sort-select, 
    .sort-wrapper .sort-panel .option {
        height: 40px;
        line-height: 18px;
    }

    .sort-wrapper .sort-select .icon {
        top: 13px;
        width: 13px;
        height: 14px;
        background-size: 150px 200px;
        background-position: 0px -18px;
    }

    .recommendation-navigation {
        .page-button,
        .navigation-next-button {
            color: #23527c;
            background-color: white;
            border: 1px solid #ddd;

            &:hover {
                color: #23527c;
                background-color: #eee;
                border: 1px solid #ddd;
            }
        }

        .page-button,
        .page-button.selected,
        .navigation-next-button {
            padding: 0;
            margin: 0;
            margin-left: -1px;
        }
    
        .navigation-next-button {
            color: inherit;
            padding: 0 10px;
        }
    }
}