.checkout {
    .checkoutProductList__list__miniSummary .headline {
        font-family: $headline-font-family-bold;
        margin-bottom: 0;
    }

    &__confirmationBox {
        background-color: $white-1;

        &__secondLine {
            font-family: $body-font-family-regular;
        }
    }
}
