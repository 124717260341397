.usp-bar-container{
    background-color: $white;
    font-family:  $body-font-family-regular;
    font-size: $body-font-size;
    color: $blue;
    line-height: $body-line-height;
    text-align: center;
    margin: 60px 0;
    .usp-bar-items-container{
        display:flex;
        justify-content: space-around;
    }

    .usp-bar-text{
        color: $blue
    }
    
    .usp-bar-icon{
        width:60px;
        height:60px;
        margin-bottom: 10px; 
        font-size: 60px;  
        
    }
}
@include media-breakpoint-down(sm) {
    .usp-bar-container{
        margin: 0 0 20px 0;
        .usp-bar-item{
            padding:0;
            margin-bottom: 10px;
        }
        .usp-bar-items-container{
            display:block;
            padding: 0px 0 0 42px;
        }
        .usp-bar-icon{
            width: 40px;
            height: 40px;
            margin: 0 20px 0 0; 
            font-size: 40px;  
            
    }

    .usp-bar-item{
        display:flex;
    }

    .usp-bar-text-container{
        top: 0;
        bottom: 0;
        margin: auto 0;
    }
    .usp-bar-text{
        margin-bottom:0;
        color: $blue
    }
    }
}
