.shoppingList {
    .moveUp,
    .moveDown {
        height: 15px;

        & > i {
            font-size: 26px;
            height: 15px;
        }

    }

    .moveUp,
    .moveDown,
    .editShoppingList,
    .removeShoppingList {
        cursor: pointer;

        i {
            transition: color 0.2s;
        }

        &:hover i {
            color: $cyan;
        }
    }

    .disabled i,
    .disabled:hover i {
        color: $gray-40;
        cursor: auto;
    }
    
    .append-horizontal-border::after,
    .prepend-horizontal-border::after {
        content: "";
        width: 1px;
        position: absolute;
        top: -20px;
        border-left: 1px solid $gray-2;
        height: calc(100% + 35px);
        left: 40px;
    }

    .prepend-horizontal-border::after {
        left: auto;
        right: 55px;
    }
}

.btn-return > i,
.btn-print > i {
    position: relative;
    top: 7px;
}

// --------------------------------------
// --- Shoppinglist Detail Area below ---
// --------------------------------------

.shoppinglist-detail {
    .product-tile {
        .row {
            @include media-breakpoint-down(md) {
                margin: 15px 0 0 0;
            }

            @include media-breakpoint-between (sm, md) {
                min-height: 370px;
            }
        }

        .delete-item {
            position: absolute;
            top: -7px;
            left: 5px;
            z-index: 2;

            @include media-breakpoint-up(lg) {
                left: auto;
                right: 60px;
            }
        }

        .myAccountAddToWlInput {
            height: 60px;

            @include media-breakpoint-up(lg) {
                height: 35px;
            }
        }
    }
}


// --------------------------------------
// --- Shoppinglist successor product ---
// --------------------------------------

.predecessor-product {
    $line-distance: 56px;
    $border-color: $redHDM;

    .product-tile {
        position: relative;
        box-shadow: 1px 4px 4px #ccc;
        
        @include media-breakpoint-down(md) {
            border: 3px solid $border-color;
        }

        @include media-breakpoint-between (sm, md) {
            box-shadow: 2px 0px 4px #999;
        }

        @include media-breakpoint-up(lg) {
            &::before,
            &::after {
                content: "";
                position: absolute;
                left: $line-distance;
                width: calc(100% - #{$line-distance * 2});
                height: 3px;
                background-color: $border-color;
            }
            
            &::before {
                top: -1px;
            }

            &::after {
                bottom: -3px;
                box-shadow: 0px 2px 4px #999;
            }
        }
    }

    .append-horizontal-border::after, 
    .prepend-horizontal-border::after {
        border-width: 3px;
        border-color: $border-color;
    }

    .triangle {
        $triange-width: 30px;

        position: absolute;
        bottom: -($triange-width * (3/4) + 3);
        left: calc(50% - #{$triange-width});
        width: $triange-width * 2;
        height: $triange-width * (3/4);
        overflow: hidden;
        z-index: 1;
        
        &::after {
            content: "";
            position: absolute;
            width: $triange-width;
            height: $triange-width;
            background: $border-color;
            transform: rotate(45deg); /* Prefixes... */
            bottom: $triange-width / 2;
            left: $triange-width / 2;
            box-shadow: 1px 1px 4px #999;
        }

        @include media-breakpoint-between (sm, md) {
            right: -($triange-width * (3/4) + 3);
            top: calc(50% - #{$triange-width});
            left: auto;
            bottom: auto;
            height: $triange-width * 2;
            width: $triange-width * (3/4);

            &::after {
                right: $triange-width / 2;
                left: auto;
                box-shadow: 1px -1px 4px #999;
            }
        }
    }
}

.successor-product {
    $match-height-margin-bottom: 20px;
    $container-distance: $match-height-margin-bottom + 1px;
    
    margin: -$container-distance 20px 20px 20px;
    
    @include media-breakpoint-between (sm, md) {
        .cc_results_list:not(.cc_grid_container) & {
            margin: 0 0 0 -31px;
            width: calc(50% + 15px);
        }
    }

    @include media-breakpoint-up(lg) {
        margin: -$container-distance 56px 0 56px;
    }
    
    .product-tile {
        background-color: $white-1;
    }

    .image-area {
        margin-right: -15px;
    }
}