.prodDetailContainer {
    &__ImgWrapper {
        @include media-breakpoint-up(md) {
            margin-top: 30px;
        }

        img {
            max-width: 200px;
            width: 100%;
            display: block;
            margin-left: auto;
            margin-right: auto;

            @include media-breakpoint-up(md) {
                max-width: 350px;
            }
        }
    }

    &__descriptionBox {
        margin-top: 20px;

        @include media-breakpoint-up(md) {
            margin-top: 30px;
        }

        p:first-of-type {
            color: $gray-20;
        }

        p:nth-of-type(even) {
            line-height: 21px;
        }

        p:not(:last-of-type) {
            font-size: 14px;
        }
    }

    &__attributesBox {
        margin-top: 20px;        
    }

    &__downloadBox {
        width: 100%;
        margin-top: 20px;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 20px;
        padding-bottom: 20px;
        background-color: $white-1;

        @include media-breakpoint-up(md) {
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    &__actionBox {
        background-color: $white-1;
        margin-top: 20px;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 10px;
        padding-right: 10px;

        @include media-breakpoint-up(md) {
            padding-left: 20px;
            padding-right: 20px;
        }

        @include media-breakpoint-up(lg) {            
            margin-top: 0;
        }        

        label {
            line-height: 21px;
        }

        &__quantityPicker {
            .input-text {
                width: 70px;
            }
        }

        &__add2Cart {
            i::before {
                color: $white-2;
            }

            &:hover {
                i::before {
                    color: $white-2;
                }
            }
        }

        &__bonusPoints {
            margin-left: -10px;
        }
    }
}