.categoryContainer,
.filterContainer {
    margin-bottom: 30px;

    .headline {
        font-family: $headline-font-family-bold;
        font-size: $categoryContainer-headline-fontsize;
        line-height: $categoryContainer-headline-lineheight;
        color: $categoryContainer-headline-color;
        margin-bottom: 10px;
    }
    
    .panel {
        border-radius: 0;
        border-width: 1px 0 0 0;
        box-shadow: none;

        &:last-child {
            border-width: 1px 0;
        }
                    
        & + .panel {
            margin-top: 0;
        }
    }
    
    .panel-default > .panel-heading,
    .panel-filled > .panel-heading {
        background-color: transparent;
        padding: 10px;
        
        .badge {
            font-family: $category-nav-badge-fontfamily;
            font-size: $category-nav-badge-fontsize;
            font-weight: normal;
            line-height: $category-nav-badge-lineheight;
            color: $category-nav-badge-color;
            background-color: $category-nav-badge-bc;
            margin-left: 10px;
        }
        
    }
    
    .panel-heading.active > .panel-title a {
        font-family: $categoryContainer-headline-active-fontfamily;
    }
    
    .panel-filled,
    .panel.open {
        background-color: $categoryContainer-collapse-bc;
        
        .panel-heading .badge {
            background-color: $white-2;
        }
    }
    
    .panel-title {
        font-family: $headline-font-family-bold;
        font-size: $category-nav-headline-fontsize;
        line-height: $category-nav-headline-lineheight;
        color: $category-nav-headline-color;

        .icon-toggle i {
            font-size: 24px;
        }
    }

    .panel-body {
        margin-top: 10px;
        padding: 0;

        .panel-heading {
            padding: 0;
        }

        .panel-title {
            font-family: $body-font-family-regular;
            font-size: $body-font-size;
            line-height: $body-line-height;
        }
    }
}