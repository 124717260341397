.gjs-editor {
    .gjs-pn-panels {
        margin-left: calc(100vw - 90px);
        font-size: 14px;
    }

}

// disable links in ccc editor mode
[data-gjs-type="wrapper"] [data-gjs-type="HomeSlider"] a {
    pointer-events: none;
}

.ccc-modal {
    .gjs-editor,
    .gjs-clm-tags,
    .gjs-trt-traits,
    .gjs-layer,
    .gjs-sm-properties,
    .gjs-toolbar-item {
        font-size: 12px;
    }

    .gjs-trt-trait {
        margin-bottom: 10px;
    }

    .gjs-layer-caret {
        font-size: 8px;
        top: 5px;
    }

    .gjs-layer-count {
        top: 2px;
    }

    .gjs-layer-move {
        padding: 2px 10px 2px 5px;
    }

    .gjs-input-unit,
    .gjs-field select,
    .gjs-radio-item-label {
        padding-top: 0;
        padding-bottom: 0;
    }

    .gjs-two-color .fa {
        color: #ddd;
    }

    .gjs-two-color .gjs-toolbar .fa {
        color: #fff;
    }

    .gjs-pn-devices-c {
        left: 0;
        top: 0;
        height: 40px;
        padding-top: 0;
    }

    input[type=text] {
        line-height: 1.5 !important;
    }

    .gjs-layer-name {
        line-height: 1;
    }
}

.ccc-contentslot-editable {
    border: 2px solid #d278c9;
    box-shadow: 0px 0px 5px #bcc3ca;
    margin-left: 5px;
    margin-right: 5px;
}