// overwrite inlinestyle from modal layer
// it calculates the width of the scrollbar and sets it as padding
#genericModal {
  padding: 0 !important;
}

.modal {
  z-index: $zindex-modal-layer;
}

.fade {
  opacity: 1 !important;

  .modal-dialog {
    top: 50px;
  }
}

.modal-backdrop {
  background-color: rgba(0,0,0,0.5);
  z-index: $zindex-modal-bg;
}

.modal-header {
  display: block;
  margin: 0 0 10px;
}

.modal-title {
  color: $blue;
}

.modal-body {
  margin: 0 0 20px;
}

.modal-content {
  padding: 30px;
  border: 0;
  border-radius: 0;
  left: 0;
  right: 0;
}

.modal-footer {
  justify-content: flex-end;
}

button.close {
  position: absolute;
  top: 10px;
  right: 10px;
  height: 40px;
  width: 40px;
  opacity: 1;
  z-index: $zindex-header-btn-close;

  i {
    font-size: 40px;
    transition: color 0.2s;
  }

  &:not(:disabled):not(.disabled):focus,
  &:not(:disabled):not(.disabled):hover i,
  &:not(:disabled):not(.disabled):focus i {
    color: $cyan;
    opacity: 1;
  }
}

// necessary because 'navigationbar-mobile' (at the sticky bottom of the page) overlaps the modal layer
@media only screen and (orientation: portrait),
       only screen and (max-height: 766px) {
  .modal {
    height: calc(100% - #{$navigation-mobile-height});
  }
}
