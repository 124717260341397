.third-template-block {
    margin-bottom: 30px;

    @include media-breakpoint-down(md) { margin-bottom: 20px; }
}

@include media-breakpoint-down(sm) {
    .h-plus-third-template {
        padding: 10px;
    }
}