.collapsed > .icon-collapse_up::before {
    content: "\e923";
}

.collapsed > .icon-minus::before {
    content: "\e901";
}

.disabled > [class*='icon-'] {
    color: $gray-20;
}

.icon-big {
    font-size: 38px;
}

.cc_no_results {
    
    .icon-search {
        font-size: 400px;
    
        @include media-breakpoint-down(md) {
            font-size: 200px;
        }
    }
}

.fileNotFound{
    
    .icon-warning {
        font-size: 500px;
    
        @include media-breakpoint-down(md) {
            font-size: 300px;
        }
    }
}
