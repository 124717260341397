// General Layouting
.container-fluid,
.container {
    max-width: 1560px;
}

@include media-breakpoint-up(xl) {
    .contentBody {
        & > .container-fluid,
        & > .container {
            padding-left: 0;
            padding-right: 0;
        }    
    }
}

#chevronUpLink {
    display: none;
}

html {
    width: 100%;
}

.icon-hover {
    &:hover {
        cursor: pointer;
    }
}

label {
    font-weight: inherit;
}

.list-group-item.lucent {
    background-color: transparent;
    border-color: transparent;
}

// makes the background transparent for the standard salesforce overlay
#overlay.modal-backdrop {
    background-color: rgba(0, 0, 0, 0);
}