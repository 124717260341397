.orderFilter {

    &__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        
        .btn-link {
            display: block; 
            text-decoration: underline;
        }
        .filterbutton {
            margin-right: 0px !important;
        }
       
    }

    &__form {
        .datepicker {
            align-items: flex-end;
        }

        .icon-calendar {
            line-height: 0.9 !important;
        }

        .input-form {
            background-color: white;
        }

    }
}