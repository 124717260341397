// The double implementation of bootstrap, the code of bootstrap 3 generate some styling errors. 
// This will be fixed / overwritten in this file

.row:after, 
.row:before {
    content: normal; // the default value
}

.table.borderless {
    tbody,
    tfoot,
    thead {
        td,
        th {
            border: 0;
        }
    }
}

.table-responsive {
    border: 0;
}