$offset: 187;
$duration: 1.4s;

.spinner {
    animation: rotator $duration linear infinite;
}

@keyframes rotator {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(270deg); }
}

.path {
    stroke-dasharray: $offset;
    stroke-dashoffset: 0;
    transform-origin: center;
    animation:
        dash $duration ease-in-out infinite, 
        colors ($duration*4) ease-in-out infinite;
}

.path_white {
    stroke-dasharray: $offset;
    stroke-dashoffset: 0;
    transform-origin: center;
    animation:
        dash $duration ease-in-out infinite, 
        colorWhite ($duration*4) ease-in-out infinite;
}

.path_pink {
    animation:
        dash $duration ease-in-out infinite, 
        colorPink ($duration*4) ease-in-out infinite;
}

.coveo-spinner {
    position: absolute;
    top: 120px;
    right: 0;
    left: 0;
    text-align: center;
    z-index: 10;
}

.variation-spinner {
    display: none;
    position: absolute;
    z-index: 100;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.myAccount-spinner {
    position: absolute;
    z-index: 100;
    margin: auto;
    left: 0;
    bottom: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.isCurrentlyLoading {
    opacity: 0.5;
}

.spinner-ContainerHeight {
    min-height: 0;

    @include media-breakpoint-up(md) {
        min-height: 500px;
    }
}

@keyframes colors {
	0% { stroke: #004178; }
	25% { stroke: #00a0e6; }
	50% { stroke: #009b3c; }
	75% { stroke: #ffc800; }
    100% { stroke: #b2b2b2; }
}

@keyframes colorWhite {
	0% { stroke: #ffffff; }
	25% { stroke: #ffffff; }
	50% { stroke: #ffffff; }
	75% { stroke: #ffffff; }
    100% { stroke: #ffffff; }
}

@keyframes colorPink {
	0% { stroke: #d278c9; }
	25% { stroke: #d278c9; }
	50% { stroke:#d278c9; }
	75% { stroke: #d278c9; }
    100% { stroke: #d278c9; }
}

@keyframes dash {
    0% { stroke-dashoffset: $offset; }
    50% {
        stroke-dashoffset: $offset/4;
        transform:rotate(135deg);
    }
    100% {
        stroke-dashoffset: $offset;
        transform:rotate(450deg);
    }
}

#fsBackdrop {
    position: absolute;
    width: 100vw;
    z-index: 100;
    margin: 0px;
    right: 0px;
    height: 1000vw;
    left: 0px;
    top: 0px;
    transition: backdrop-filter 0.5s ease 0s;
}

#fsSpinner {
    position: fixed;
    width: 100%;
    z-index: 125;
    display: flex;
    margin: 0px;
    right: 0px;
    height: 100%;
    left: 0px;
    transition: opacity 0.5s ease 0s; top: 0px;
}