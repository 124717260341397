.checkout4-payment-section {
  & ~ input[type="radio"] {
    display: none; /*removes original button*/
  }
  .overviewBox__promotionBox {
    div:last-of-type {
      border-top: none;
    }
  }
  & ~ .payment-selection-label {
    /* add this so dot is relative to the label */
    margin: 0 0 10px 0;
    position: relative;
    display: inline-block;
  }

  & ~ .payment-selection-label::before {
    content: "";
    height: 20px;
    width: 20px;
    display: block;
    position: absolute;
    top: 12px;
    border-radius: 50%;
    border: 1px solid #d3d7da;
  }

  & ~ .payment-selection-label::after {
    content: " ";
    border-radius: 50%;
    display: block;
    width: 12px;
    height: 12px;
    background-color: $blue;
  }

  /* remove the first label from this selector */
  input[type="radio"]:checked {
    & ~ div label.payment-selection-label::after {
      content: "";
      height: 12px;
      width: 12px;
      border-radius: 50%;
      display: block;
      position: absolute;
      top: 23px;
      left: 19px;
      background-color: #004178;
    }

    & ~ .payment-content-box {
      display: block;
      padding: 20px;

      @include media-breakpoint-up(md) {
        width: calc(100% - 400px);
        min-height: 0px;
        position: absolute;
        top: 0;
        right: 15px;
      }
    }

    & ~ .payment-type {
      border: 1px solid $blue;
    }
  }

  @include media-breakpoint-down(xs) {
    .checkout .home_slider {
      margin-bottom: 0;
    }
    .payment-content-box {
      padding: 10px;
    }
    .payment-selection-label {
      font-size: $body-font-size-seo;
    }
    .billing-address-header {
      margin-bottom: 10px;
    }
  }

  .billing-address-header {
    margin-bottom: 20px;
  }

  .payment-type {
    /*padding: 15px 0; */
    border-radius: 5px;
    border: 1px solid #d3d7da;
    margin-bottom: 20px;
    width: 100%;
    min-height: 60px;
    background: $white;
    @include media-breakpoint-up(md) {
      width: 350px;
    }
  }

  .payment-section-header {
    margin: 0 0 10px 0;
  }

  // div  container über der checkbox
  .payment-select-section {
    display: flex;
    top: 0;
    bottom: 0;
    margin: auto 0px;
    width: 100%;

    label {
      width: 100%;
      margin-bottom: 0;

      &:before {
        content: " ";
        display: inline-block;
        top: 5px;
        margin: 0 10px 0 0;
        width: 20px;
        height: 20px;
        border-radius: 11px;
        border: 2px solid transparentize($blue, 0.9);
        background-color: transparent;
        vertical-align: middle; /* add this so text is vertically centred next to ring */
        transition: border 1s;
      }
    }
  }
  .checkout__orderAdressBox{
    @include media-breakpoint-up(md) {height: 450px;}
  }
}

.paymentTypeContainer {
  .cc_heading {
    display: none;
  }

  .panel-default {
    border: none;
    margin-bottom: 0;
  }

  .cc_po_action_buttons {
    display: none;
  }

  .panel-body {
    padding: 0;
  }

  .checkbox {
    display: none;
  }
}
// radiobutton styling

.payment-radio {
  margin-right: 15px;
  height: 20px;
  width: 30px;
  line-height: 21px;
  margin: 0 10px 0 0;
  display: none;
}

.payment-select-label {
  margin: 0;
  font-size: $body-font-size-seo;
  width: 42vw;
}

.payment-icon {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 15px;
  margin-bottom: 0;
}

.payment-content-box {
  background-color: $white;
  margin: 0 0 20px 0;
  padding: 10px 10px;
  display: none;
}

.payment-content-text {
  margin-bottom: 0;
  font-size: 16px;
  line-height: $body-line-height;
}

.payment-input {
  width: 100%;
  height: 40px;
  padding: 10px 5px;
  border-radius: 5px;
  margin-bottom: 10px;
  border: 1px solid lightgray;
}

.payment-productlist-underline {
  border-bottom: 1px solid $white;
  padding-bottom: 20px;
  margin-bottom: 30px;
}

.payment-productlist-footer {
  font-size: $body-font-size;
}

.payment-credit-card {
  width: 250px;
}

.payment-image-icon {
  width: 80px;
  height: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}
.form-horizontal.newPOForm.cc_form-horizontal.cc_new_po_form{
  display: none;
}

