@font-face {
    font-family: "Heidelberg Antiqua-10";
    font-display: swap;
    font-style: normal;
    font-weight: 500;
    src: url("../fonts/heidelbergantiquaml_webfont_3.woff");
}

@font-face {
    font-family: "Heidelberg Gothic-10";
    font-display: swap;
    font-style: normal;
    font-weight: 500;
    src: url("../fonts/heidelbggothicwebml_1.woff");
}

// ** This font in normal font weight was never used **
// @font-face {
//     font-family: "Heidelberg Gothic-18";
//     font-display: swap;
//     font-style: normal;
//     font-weight: 500;
//     src: url("../fonts/HeidelbgGothicWebMl.woff");
// }

@font-face {
    font-family: "Heidelberg Gothic-18";
    font-display: swap;
    font-style: normal;
    font-weight: 500;
    src: url("../fonts/HeidelbgGothicWebMl-Bold.woff");
}

body {
    font-family: $body-font-family-regular;
    font-size: $body-font-size;
    line-height: $body-line-height;
    
}

.text {
    font-family: $body-font-family-regular;

    &--highlight {
        font-family: $body-font-family-regular;

        &--bold {
            font-family: $headline-font-family-bold;
        }
    }

    &--seo {
        font-size: $body-font-size-seo;
        line-height: $body-line-height-seo;

        &--bold {
            font-family: $headline-font-family-bold;
        }
    }

    &--small {
        font-size: $body-font-size-small;
        line-height: $body-line-height-small;
    }

    &--bold {
        font-family: $headline-font-family-bold;
    }

    &--blue {
        color: $blue;
    }

    &--gray {
        color: $gray-20;

        &::before {
            color: $gray-20;
        }
    }
    &--white {
        color: white;
    }
}

h1, h2, h3, h4, h5, .h1, .h2, .h3, .h4, .h5 {
    font-family: $headline-font-family-regular;
    margin: 0;

    &--bold {
        font-family: $headline-font-family-bold;
    }
}

h1, .h1 {
    font-size: $headline-h1-font-size - 8;
    line-height: $headline-h1-line-height - 9;

    @include media-breakpoint-up(lg) {        
        font-size: $headline-h1-font-size;
        line-height: $headline-h1-line-height;
    }
}

h2, .h2 {
    font-size: $headline-h2-font-size - 16;
    line-height: $headline-h2-line-height - 19;

    @include media-breakpoint-up(lg) {        
        font-size: $headline-h2-font-size;
        line-height: $headline-h2-line-height;
    }
}

h3, .h3 {
    font-size: $headline-h3-font-size - 4;
    line-height: $headline-h3-line-height - 5;

    @include media-breakpoint-up(lg) {        
        font-size: $headline-h3-font-size;
        line-height: $headline-h3-line-height;
    }
}

h4, .h4 {
    font-size: $headline-h4-font-size;
    line-height: $headline-h4-line-height;

    @include media-breakpoint-up(lg) {        
        font-size: $headline-h4-font-size;
        line-height: $headline-h4-line-height;
    }
}

h5, .h5 {
    font-size: $headline-h5-font-size;
    line-height: $headline-h5-line-height;

    @include media-breakpoint-up(lg) {        
        font-size: $headline-h5-font-size;
        line-height: $headline-h5-line-height;
    }
}

a, .link {
    color: $blue;
    transition: color .2s;
    cursor: pointer;

    &:hover,
    &:hover > i {
        color: $cyan;
    }

    &.no-hover {
        &:hover {
            color: inherit;
            text-decoration: none;
        }
    }
}

.text-link {
    text-decoration: none;
    box-shadow: inset 0 -1px 0 0 $blue;
    transition: box-shadow .2s;

    &:hover,
    &:focus {
        text-decoration: none;
        box-shadow: inset 0 -2px 0 0 $blue;
    }

    &.text-link-blue,
    &.text-link-blue:hover {
        color: $blue;
    }

    &.text-link-gray,
    &.text-link-gray:hover {
        color: $gray-20;
    }
}

.text-link-red {
    text-decoration: none;
    box-shadow: inset 0 -1px 0 0 $red;
    transition: box-shadow .2s;

    &:hover,
    &:focus {
        text-decoration: none;
        box-shadow: inset 0 -2px 0 0 $red;
    }

    &.text-link-red,
    &.text-link-red:hover {
        color: $red;
    }
}

.fa {
  font-family: FontAwesome !important;
}