.deskLayout {
    margin-top: 120px;
    min-height: calc(100vh - 120px);
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(md) {
        margin-top: 187px;
        min-height: calc(100vh - 187px);
    }

    & > .cc_custom_content_slot {
        margin: 0;
    }
}

.checkout .deskLayout {
    min-height: 100vh;
}

header {
    height: 110px;
    background-color: white;
    position: fixed;
    top: 0;
    z-index: $zindex-header;
    width: 100%;
    transition: height .2s;

    &.minimized {
        height: 60px;

        .header__searchBar {
            height: 0;
            overflow: hidden;
        }
    }

    &.expanded {
        height: 110px;

        .header__searchBar {
            height: auto;
            overflow: visible;
        }
    }
}

.header {
    @include media-breakpoint-up(md) {
        &::before {
            content: "";
            display: block;
            height: 5px;
            position: absolute;
            left: 0;
            width: 100vw;
            max-width: 100%;
            background-color: $gray-1;
        }
    }

    &__menu {
        display: block;
        width: 100%;
        background-color: $gray-1;
        height: calc(100vh - 60px);
        position: absolute;
        bottom: 0;
        top: 60px;
        right: -100%;
        z-index: $zindex-header-menu;
        overflow: hidden;
        transition: right .2s;
        border-bottom: 1px solid $gray-2;
        
        @include media-breakpoint-up(md) {
            right: 0;
            top: 0;
            position: static;
            height: auto;
            overflow: visible;
            background-color:white;
            margin: 0 auto;
            left: 0;
            padding-left: 15px;
            padding-right: 15px;
        }
        
        @include media-breakpoint-up(xl) {
            padding: 0 calc(50vw - 790px);
        }
        
        &.toggled, .toggled {
            right: 0;
            margin: 0 auto;
            left: 0;
        }

        &.header-checkout {
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
    }

    position: fixed;
    top: 0;
    width: 100%;

    &__topBar {
        position: relative;
        min-height: 60px;
    }

    &__btnWrapper {
        height: 100%;
        width: 50px;        
        position: absolute;
        top: 0;
        right: 0;
    }

    &__btnContent {
        height: 34px;
        width: 34px;

        i::before {
            color: $gray-20;
        }
    }

    &__searchBar {        
        width: 100%;
        height: 42px;
        font-family: $body-font-family-regular;
        font-size: 16px;
        color: $gray-20;
        border-radius: 3px;
        @include transition(height .2s);

        .search_input {
            width: 100% !important;
        }

        .input-group-btn {
            display: block;
        }
    }

    &__homeImg {
        width: 100%;
        display: block;
        height: 77px;

        img {
            height: 100%;
            margin-top: 5px;
            margin-left: -36px;
        }
    }

    &__subMenuImg {
        height: 140px;
        width: 100%;
        position: relative;
        overflow: hidden;
        background-size: 0;

        &::before {
            content: "";
            display: block;
            height: 100%;
            width: 100%;
            background: no-repeat center/cover;
            background-image: none;
            position: absolute;
            transform: scale(1);
            background-image: inherit;
            transition: transform 0.2s;
        }

        &:hover::before {
            transform: scale(1.2);
            cursor: pointer;
        }
    }

    &__navigationSearchBox {
        margin-top: 55px;

        .row {
            align-items: center;
        }

    }

    &__searchButton {
        z-index: 550 !important;
    }

    &__loginSection {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        * {
            position: relative;
        }

        & > a {
            margin-right: 20px;
        }

        .icon-contract,
        .icon-man_portrait {
            color: $gray-20;
        }
    }

    &__iconPanel {
        background-color: white;
        height: 40px;

        a {
            justify-content: center;
            align-items: center;
            border-top: 1px solid $gray-2;
            color: $gray-20;

            &:first-of-type {
                border-right: 1px solid $gray-2;
            }
        }

        p {
            display: inline;
        }
    }

    &__languagePicker {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 40px;
        background-color: $white-1;

        @include media-breakpoint-up(md) {
            top: 0;
            background-color: transparent;
            justify-content: flex-end;
        }
        
        .navbar-nav li {
            padding-right: 0;
        }

        .icon-world {
            width: 18px; 
        }
    }

    &__languageAnchor {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        color: $gray-20;

        &:hover {
            text-decoration: none;
        }

        @include media-breakpoint-up(md) {
            font-size: 14px;

            i {
                font-size: 28px;
            }
        }
    }

    &__backButton {
        background-color: $white-1;
        min-height: 40px;

        @include media-breakpoint-up(md) {
            display: none;
        }

        p {
            position: absolute;
            left: 30px;
        }

        i {
            position: absolute;
            margin-top: 3px;
        }
    }

    &__popUp {
        color: $blue;
        display: none;
        position: absolute;
        top: 50px;
        left: -150px;
        text-align: center;
        z-index: 1000;

        @include media-breakpoint-up(lg) {
            left: -143px;
        }

        &::before,
        &::after {
            content: "";
            display: block;
            height: 30px;
            width: 30px;
            position: absolute;
            right: 65px;
            border: 15px solid;
            pointer-events: none;
        }

        &__link {
            text-decoration: none;
            font-size: 14px;
            display: inline;
            box-shadow: inset 0 -1px 0 0 $blue;
            transition: box-shadow .2s;

            &:hover {
                color: $blue;
                text-decoration: none;
                box-shadow: inset 0 -2px 0 0 $blue;
            }
        }
    
        &__bottom {
            line-height: 60px;
            width: calc(100% + 30px);
            background-color: $white-1;
            margin-left: -15px;
            margin-right: -15px;
            margin-bottom: -15px;

            &--twolines {
                line-height: 34px;
            }

            &--small {
                margin: 0;
                width: 100%;
            }

            a {
                font-size: 14px;
            }
        }

        &::before {            
            border-color: transparent transparent $white-2 transparent;
            top: -28px;
            z-index: 100;
        }

        &::after {            
            border-color: transparent transparent $gray-1 transparent;
            top: -30px;
        }
    }

    &__benefitsList {
        text-align: left;
        list-style: none;

        li {
            display: flex;

            &::before {
                content: "\e91e";
                font-family: hdm_font_icon;
                margin-left: -20px;
                margin-right: 5px;
                font-size: 24px;
            }
        }
    }

    &__logInBtn {
        width: 80%;
    }

    &__effectiveAccountLink {
        min-height: 65px;

        li.effAccount {
            max-height: 0;
        }
    }

    &__accountContent {
        i {
            font-size: 24px;
            top: 5px;

            &::before {
                color: $blue;
            }
        }
    }

    &__miniCart {
        width: 330px;
        left: -245px;

        &.empty {
            left: -277px;

            @include media-breakpoint-up(lg) {
                left: -270px;
            }
        }

        &::before,
        &::after {
            left: 285px;
        }

        &List {
            text-align: left;

            &__listWrapper {
                max-height: 220px;
                overflow-x: hidden;
                overflow-y: auto;
            }

            &__itemWrapper {
                border-bottom: 1px solid $gray-2;
            }

            &__prodName {
                a {
                    white-space: nowrap;
                    display: block;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
            }

            &__totals {
                color: $blue;

                button {
                    width: 80%;
                }
            }

            &__img {
                img {
                    width: 100%;
                }
            }
        }
    }

    &__shoppingList {
        &__listWrapper {
            height: 190px;
        }

        &__listItem {
            border-bottom: 1px solid $gray-2;
            padding:5px;
            margin-top:15px;

            .text--bold {
                white-space: nowrap;
                display: inline-block;
                text-overflow: ellipsis;
                overflow: hidden;
                width: 100%;
            }
        }
    }

    &__account,
    &__shoppingList {
        width: 250px;
    }

    &__arrow {
        position: absolute;
        right: 0;
    }

    &__hasSubmenu {
        @include media-breakpoint-up(md) {
            .subMenuHover {
                opacity: 0;
                visibility: hidden;
                margin-top: -20px;
                transition: opacity .2s, visibility .2s, margin-top .2s;
            }

            .hoverOverlay {
                content: "";
                display: block;
                height: 100vh;
                width: 100vw;
                background-color: rgba(0,0,0,0.5);
                position: absolute;
                z-index: -2;
                top: 0;
                left: 0;
                opacity: 0;
                visibility: hidden;
                transition: opacity .2s, visibilty .2s;
            }

            &.jqhover {
                a {
                    color:  $blue;

                    &::before {
                        background-color: $cyan; 
                        height: 3px;
                    }
                }

                .hoverOverlay {
                    opacity: 1;
                    visibility: visible;
                }
                
                .subMenuHover {
                    opacity: 1;
                    visibility: visible;
                    margin-top: 0;
                }
            }
        }
    }
}

.header .locale_link{
    cursor: default !important;
}

.header .header__hasSubmenu.jqhover { 
    @include media-breakpoint-up(md) {
        .js-menuHover-navAnchor {
            color:  $cyan;
        }
    }
}

@include media-breakpoint-up(xl) {
    .cc_breadrumb_container {
        padding-left: 0;
        padding-right: 0;
    }
}
