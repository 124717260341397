.image-and-text-firstheader {
    @extend .slider-firstheader;
    
    .image-and-text-container & {
        font-size: 32px;
        line-height: 1.2;
    }
}

.image-and-text-secondheader {
    @extend .slider-secondheader;
    
    .image-and-text-container & {
        font-size: 32px;
        line-height: 1.2;
    }
}
