.btn {
  background-color: $blue;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
  color: white;
  font-size: 16px;
  position: relative;
  font-family: $body-font-family-regular;

  i {
    color: $white-2;

    &::before {
      transition: color 0.15s ease-in-out;
    }
  }
  
  &:hover {
    border-color: $cyan;
    background-color: $cyan;
    color: white;
  }

  &[disabled]:hover {
    border-color: #ccc;
    background-color: $cyan;
    color: white;
    opacity: 0.4;
  }

  &-lg {
    height: 40px;
    font-size: 16px;
    line-height: 28px;
  }

  // Colors

  &-default {
    &:active,
    &:focus {
      color: $white-2;
      background-color: $blue;
      border-color: none;
      box-shadow: none;
    }
  }

  &-secondary {
    background-color: transparent;
    color: $blue;
    border: 2px solid $blue;

    i {
      color: $blue;
    }

    &:hover,
    &:not(:disabled):not(.disabled):focus,
    &:not(:disabled):not(.disabled):active {
      background-color: transparent;
      color: $cyan;
      border-color: $cyan;

      i {
        color: $cyan;
      }
    }
  }

  &-tertiary {
    background-color: $cyan;
    color:  white;
    border: 2px solid white;
    transition: color 0.2s ease,
    border-color 0.2s ease;
    
    &:hover,
    &:not(:disabled):not(.disabled):focus,
    &:not(:disabled):not(.disabled):active {
      background-color: $cyan;
      color:  $blue;
      border-color: $blue;
      outline: transparent;
    }
  }

  &-transparent {
    background-color: transparent;

    &:hover {
      background-color: transparent;
    }
  }

  &-invisible {
    border: none;
    background-color: transparent;

    &:hover {      
      border: none;
      background-color: transparent;
    }
  }

  &-iconButton {
    padding-left: 40px;

    i::before {
      position: absolute;
      left: 15px;
      top: 2px;
      transition: color .2s;
    }
  }

  &-grey {    
    background-color: $gray-200;
    i {
      color: $blueHDM;
    }
  }
}

.btn-primary {
  padding-right: 30px;
  padding-left: 30px;
}

.cartButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: $cyan;
  color: white;
  font-family: $body-font-family-regular;
  height: 40px;
  border-radius: 30px;
  padding-left: 10px;
  padding-right: 15px;

  @include media-breakpoint-up(lg) {
    padding-left: 15px;
  }

  &:hover,
  &:active,
  &:focus {
    color: white;
    text-decoration: none;
  }
  
  &.empty {
    background-color: $white-2;
    
    i::before {
      color: $gray-20;
    }
  }

  p {
    margin-bottom: 0;
    white-space: nowrap;
    color: white;
  }

  i {
    &::before {
      color: white;
    }
  }
}

// necessary to prevent the layer from closing when jumping with the mouse from the button to the overlay 
.cartButton::after,
#goToShoppingList::after,
.goToAccount-flyout > a::after {
  content: "";
  display: block;
  background: transparent;
  width: 80%;
  height: 10px;
  position: absolute;
  bottom: -10px;
}

.modal-footer .btn {
  margin-bottom: 0;
}

.switch-btn {
  position: relative;
  background-color: $white-2;
  display: inline-flex;
  z-index: 1;
  border-radius: 3px;


  input[type=radio] {
    height: 0;
    width: 0;
    visibility: hidden;
    position: absolute;
  }
  
  label {
    position: relative;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 40px;
    text-align: center;
    color: $gray-20;
    cursor: pointer;
    transition: color 0.3s;
    margin: 0;
    padding: 0 20px;
  }
  
  label:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $blue;
    transition: 0.3s;
    z-index: -1;
    border-radius: 3px;
  }
  
  input:checked + label {
    color: white;
  }
  
  input + label:after {
    transform: translateX(100%);
    width: 100%;
  }
  
  input:checked + label:after {
    left: 100%;
    transform: translateX(-100%);
    width: 100%;
  }
  
  label + input + label:after {
    transform: translateX(-100%);
    width: 10px;
    left: 0;
  }
  
  label + input:checked + label:after {
    left: 100%;
    width: 100%;
  }
}

button:focus {
  outline: 0;
}

.btn-registerUser {
  border-color: #004178;
  background-color: transparent;
  color: #004178;
  border: 2px solid #004178;
  padding: 5px 44px 5px 10px;
  width: max-content;
  
  &:hover {
    border-color: #00a0e6;
    background-color: transparent;
    color: #00a0e6;
    
    i {
      color: #00a0e6;
    }
  }
  i {
    width: 20px;
    height:20px;
    color:#004178;
  }
}

.btn-file input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  background: red;
  cursor: inherit;
  display: block;
}
