.checkout {
    header {
        position: relative;
        height: auto;
    }

    .home_slider {
        position: sticky;
        position: -webkit-sticky;
        top: 0;
        z-index: 10;
        padding-top: 15px;
        margin-top: -15px;
        margin-bottom: 20px;
        background-color: $white-2;

        &.sticky {
            box-shadow: 0px 1px 10px rgba(0,0,0,0.1);
        }
    }

    .header__homeImg_tiny {
        display: none;
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        margin-left: -23px;
    }

    .sticky .header__homeImg_tiny {
        @include media-breakpoint-up(xl) {
            display: block;
        }     
    }

    .deskLayout {
        margin-top: 0;
    }

    .header__menu {
        border: 0;
    }

    .header__navigationFooter {
        width: auto;
    }

    .header__accountData {
        float: left;
        height: 40px;
        display: flex;
        align-items: center;
        margin-right: 30px;
        color: $gray-20;
        font-size: $body-font-size-seo;
    }

    .header__languagePicker {
        position: static;
        width: auto;
    }

    .header {
        position: relative;

        &__checkout {
            display: flex;
            margin-bottom: 20px;

            li:first-of-type {
                border-radius: 20px 0 0 20px;
            }
        
            li:last-of-type {
                border-radius: 0 20px 20px 0;
            }
        
            @include media-breakpoint-up(xl) {
                width: 70%;
            }
      
            &__item {
                background-color: #f3f4f8;
                color: #7d838b;
                height: 40px;
                font-size: 16px;
                font-size: 16px;
                border: $white-2 solid 0;
                border-right-width: 2px;
                flex-grow: 1;

                i {
                    font-size: 24px;
                }
        
                &.active {
                    border-right-width: 0;

                    & > div {
                        position: relative;
                        background-color: $cyan;
                        width: calc(100% - 40px);
                        color: $white-2;
                        z-index: 1;
                        
                        i {
                            color: $white-2;
                        }
                        
                        &::before,
                        &::after {
                            content: "";
                            width: 40px;
                            height: 100%;
                            background-color: $cyan;
                            border-radius: 50%;
                            position: absolute;
                            left: -20px;
                           z-index: -1;
                        }
                        
                        &::after {
                            left: auto;
                            right: -20px;
                        }
                    }
                }
                
                &.checked {
                    color: $blue;

                    i {
                        color: $green;
                    }

                    i[class*="icon-"]::before {
                        content: "\e91e"
                    }

                    &:not(.disabled):hover,
                    &:not(.disabled):hover i {
                        color: $cyan;
                        cursor: pointer;
                    }
                }

                &.checked-last {
                    border-right-width: 0;
                }
                
                &.large {
                    width: calc(20% + 20px);
                }
                
                &.disabled {
                    cursor: not-allowed;
                }
            }
        }
    }

      
    .container-fluid {
        max-width: 1560px;
    }
}